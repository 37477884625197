import { fetchUtils } from "react-admin";
import { constants } from "../config";

const httpClient = fetchUtils.fetchJson;
const baseUrl = [constants.api.url, "/auth"].join("");

const authProvider = {
  login: async ({ username, password }) => {
    await httpClient(`${baseUrl}/login/admin`, {
      withCredentials: true,
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        username,
        password,
      }),
    }).then(({ json, status }) => {
      // console.log("LOGIN RESP", { json, status });
      if (status === 200) {
        // console.log("setting token", json.token);
        localStorage.setItem(constants.store.admin, JSON.stringify(json.token));
        return Promise.resolve();
      }
      return Promise.reject();
    });
  },
  logout: async () => {
    // console.log("DO LOGOUT");
    await httpClient(`${baseUrl}/logout`, {
      withCredentials: true,
      credentials: "include",
    }).then(({ status }) => {
      if (status === 200) {
        localStorage.removeItem(constants.store.admin);
        return Promise.resolve();
      }
      return Promise.reject();
    });
  },
  checkAuth: async () => {
    const authStore = localStorage.getItem(constants.store.admin);
    await httpClient(`${baseUrl}/check`, {
      withCredentials: true,
      credentials: "include",
    }).then(async ({ json }) => {
      // console.log("Check Auth", { json, authStore });
      if (authStore && json.check) return Promise.resolve();
    //   await this.logout();
      return Promise.reject();
    });
  },
  checkError: async ({ status }) => {
    // console.log("checkError()", { status });
  },
  getIdentity: () => Promise.resolve({}),
  getPermissions: () => Promise.resolve(''),
};

export default authProvider;
