import ListEntry from "./List";
import EditEntry from "./Edit";
// import CreateEntry from "./Create";

const Entry = {
  list: ListEntry,
  edit: EditEntry,
  // create: CreateEntry,
};

export default Entry;
