import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useGetList } from "react-admin";
import AccordianCategoryEntries from "./AccordionCategoryEntries";

function Category({ category, organization, callback }) {
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);

  const disabled = category.entries.length === 0;
  return (
    <Accordion disabled={disabled} expanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore onClick={toggleExpanded} />}
        sx={{ background: "#e3f2fd26" }}
      >
        <Grid container spacing={1} sx={{ pt: 1, pb: 1 }}>
          <Grid item onClick={toggleExpanded}>
            <Typography variant="body2" sx={{ verticalAlign: "middle" }}>
              ({category.id})
            </Typography>
          </Grid>
          <Grid item xs onClick={toggleExpanded}>
            <Typography variant="body2">{category.title}</Typography>
          </Grid>

          <Grid item onClick={toggleExpanded}>
            <Typography variant="body2">
              Entries: {category.number_of_entries}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              disabled={disabled}
              sx={{ height: "10px", mt: -0.5 }}
              size={"small"}
              onClick={() =>
                callback(
                  { target: { id: "newEntries", value: category.entries } },
                  { organization, category: category.title }
                )
              }
            >
              Add All In Category
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <AccordianCategoryEntries data={category.entries} {...{ callback }} />
      </AccordionDetails>
    </Accordion>
  );
}

function AccordianCategories({
  organization,
  year,
  callback,
  loadingCallback,
  entriesCallback,
}) {
  // const [filterPaidEntry, setFilterPaidEntry] = React.useState(true);
  const [filterSubmittedEntry, setFilterSubmittedEntry] = React.useState(true);
  const [filterEntryLanguage, setFilterEntryLanguage] = React.useState('all');
  const { data,  isLoading, } = useGetList(
    "category",
    {
      pagination:{
        perPage: 99999
      },
      filter: {
        organization,
        year,
        entry: { submitted: filterSubmittedEntry, language: filterEntryLanguage },
      }, //paid: filterPaidEntry
      
    }
  );

  React.useEffect(() => {
    loadingCallback && loadingCallback(isLoading);
  }, [isLoading,loadingCallback]);

  React.useEffect(() => {
    if (data && data.length > 0)
      entriesCallback(
        _.flatten(
          data.map((category) => {
            const entries = category.entries.map((entry) => {
              if (organization) entry.organization = organization;
              if (category.title) entry.category = category.title;
              return entry;
            });

            return entries;
          })
        )
      );
  }, [data,entriesCallback,organization]);

  // const toggleFilterPaidEntry = (force) => {
  //   if (typeof force === "boolean") setFilterPaidEntry(force);
  //   else setFilterPaidEntry((prev) => !prev);
  // };

  const toggleFilterSubmittedEntry = (force) => {
    if (typeof force === "boolean") setFilterSubmittedEntry(force);
    else setFilterSubmittedEntry((prev) => !prev);
  };


  // const handleFilterToggleClick = (force) => {
  //   if (force && !filterPaidEntry) toggleFilterPaidEntry(force);
  //   if (!force && filterPaidEntry) toggleFilterPaidEntry(force);
  // };

  const handleFilterToggleClick = (el) => {
    const force = el.target.value === 'true'
    if (force && !filterSubmittedEntry) toggleFilterSubmittedEntry(force);
    if (!force && filterSubmittedEntry) toggleFilterSubmittedEntry(force);
  };
  const handleFilterLanguageClick = (el) => {
    const language = el.target.value
    setFilterEntryLanguage(language)
  };

  //   const Category = React.useCallback(() => {
  //     console.log("Category", { data });
  //     return (

  //   }, [data]);

  if (isLoading) return <p>Loading {organization} Categories...</p>;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs>
            <ToggleButtonGroup
            size={'small'}
              value={filterSubmittedEntry}
              exclusive
              aria-label={"Toggle submitted entry filter button group"}
              onChange={ handleFilterToggleClick}
            >
              <ToggleButton value={true}>Submitted</ToggleButton>
              <ToggleButton value={false}>All</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item>
            <ToggleButtonGroup
            size={'small'}
              value={filterEntryLanguage}
              exclusive
              aria-label={"Filter by language"}
              onChange={handleFilterLanguageClick}
            >
              <ToggleButton value={"en"}>En</ToggleButton>
              <ToggleButton value={'all'}>En/Fr</ToggleButton>
              <ToggleButton value={"fr"}>Fr</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      {data &&
        data
          .sort((a, b) => (a.entries.length > b.entries.length ? 1 : -1))
          .sort((a, b) => (a > b ? 1 : -1))

          .map((category) => {
            return (
              <Category
                key={`category-${category.id}`}
                {...{ category, callback, organization }}
              />
            );
          })}
      </Grid>

     
    </Grid>
  );
}

export default AccordianCategories;
