import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import RecordCard from "../../Common/RecordCard";
import RecordTextField from "../../Common/RecordTextInput";



const CategoryLabelCard = ({ category, firstInputRef, handleChange }) => {
  
    const LabelEn = React.useCallback(
    () => (
      <RecordCard title={"English"}>
        <RecordTextField
          error={category.errors && category.errors.title}
          withRef={firstInputRef}
          label={"Category Label (En)"}
          value={category.title}
          id={category.id}
          source={"title"}
          handleChange={handleChange}
          tabIndex={0}
        />
      </RecordCard>
    ),
    [category]
  );

  const LabelFr = React.useCallback(
    () => (
        <RecordCard title={"French"}>
        <RecordTextField
          label={"Category Label (Fr)"}
          value={category.title_fr}
          id={category.id}
          source={"title_fr"}
          handleChange={handleChange}
          tabIndex={1}
        />
      </RecordCard>
    ),
    [category]
  );

  return (
    <Card>
      <CardHeader title={"Labels"} />
      <CardContent>
        <LabelEn/>
        <LabelFr/>
      </CardContent>
    </Card>
  );
};


export default CategoryLabelCard