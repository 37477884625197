import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TopToolbar,
  downloadCSV,
  CreateButton,
  ExportButton,
  DateField,
  useListContext,
  Loading,
  FilterLiveSearch,
  UrlField,
  FunctionField,
} from "react-admin";
// import { Box, Typography } from "@material-ui/core";
import { constants } from "../../config";
// import { ImportButton } from "../Common/";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import jsonExport from "jsonexport/dist";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Link,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";
import { Download, ExpandMore, FileDownload } from "@mui/icons-material";
import ButtonImport from "../Common/ButtonImport";
import Mono from "../Common/Monospace";
import { CSVLink } from "react-csv";

// const CategoryImportButton = (props) => (
//   <ImportButton {...{ ...props, type: "category" }} />
// );

// const FieldImportButton = (props) => (
//   <ImportButton {...{ ...props, type: "field", resource: 'field'}} />
// );

// const EmptyCategory = (props) => {
//   return (
//     <Box textAlign="center" m={1}>
//       <Typography variant="body1">
//         No Categories found for {props.year || "no year"}
//       </Typography>
//     </Box>
//   );
// };

const DownloadCategoryCSV = () => (
  <Button variant="text" startIcon={<FileDownload />}>
    <CSVLink
      data={[
        [
          "Type",
          "Title",
          "Title (fr)",
          "Price",
          "Discounted Price",
          "Start Date",
          "End Date",
          "Discount End Date",
          "Active",
          "Field Type",
          "Required",
          "Category Reference",
          "Placeholder",
          "Placeholder (Fr)",
          "Priority",
        ],
        [
          "type",
          "title_en",
          "title_fr",
          "price",
          "discount",
          "start",
          "end",
          "discountEndDate",
          "active",
          "field_type",
          "required",
          "category_ref",
          "placeholder",
          "placeholder_fr",
          "priority",
        ],
      ]}
      filename={"category-template-nmaf-admin-v2023-1.csv"}
      style={{ textDecoration: "none" }}
    >
      Download template
    </CSVLink>
  </Button>
);

const EmptyCategory = (props) => {
  const { organization, year } = props;

  return (
    <Box sx={{ mt: 8, width: "100%" }}>
      <Card>
        <CardHeader title="No categories found" />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant={"body1"}>
                There are no categories for this year & program.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item>
                  <CreateButton />
                </Grid>
                <Grid item>
                  <ButtonImport type={"category"} {...{ organization, year }} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item>
                  <DownloadCategoryCSV />
                </Grid>
                <Grid item xs></Grid>
              </Grid>
            </Grid>
          </Grid>

          <Accordion elevation={0} sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{ pl: 0, pr: 0, borderTop: "none !important" }}
            >
              Learn more about importing Categories
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 0, pr: 0 }}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <Typography variant={"body1"}>
                    <strong>Importing Catgories</strong>
                  </Typography>
                  <Typography variant={"body1"} gutterBottom>
                    Quickly create Categories and related Fields by importing a
                    CSV above.
                  </Typography>
                  <Typography variant={"body1"} gutterBottom>
                    The table below describes each column header. Note that the
                    columns can be in any order and the labels must match these
                    here identically. Use the template to get started.
                  </Typography>
                  <Typography variant={"body1"} gutterBottom>
                    Note that if you do not add any fields, the Category will
                    still be created.
                  </Typography>
                  <DownloadCategoryCSV />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant={"body1"}>
                    <strong>Default Fields</strong>
                  </Typography>
                  <Typography variant={"body1"}>
                    When creating a Category using the CSV import, the following
                    fields are <strong>creted automatically</strong>, as they
                    are required (click to expand to see default properties):
                  </Typography>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="body1">Title</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size={"small"}>
                        <TableRow>
                          <TableCell variant="head">Title (En)</TableCell>
                          <TableCell>Submission Title</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Title (Fr)</TableCell>
                          <TableCell>Titre de la candidature</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (En)</TableCell>
                          <TableCell>
                            Exactly as it should be credited
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (Fr)</TableCell>
                          <TableCell>
                            Exactement comme il faut le créditer
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Required</TableCell>
                          <TableCell>true</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Priority</TableCell>
                          <TableCell>0</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Type</TableCell>
                          <TableCell>title</TableCell>
                        </TableRow>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Typography variant="body1">Publication</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size={"small"}>
                        <TableRow>
                          <TableCell variant="head">Title (En)</TableCell>
                          <TableCell>Publication Name</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Title (Fr)</TableCell>
                          <TableCell>Nom de la publication</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (En)</TableCell>
                          <TableCell>
                            Exactly as it should be credited
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (Fr)</TableCell>
                          <TableCell>
                            Exactement comme il faut le créditer
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Required</TableCell>
                          <TableCell>true</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Priority</TableCell>
                          <TableCell>1</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Type</TableCell>
                          <TableCell>publication</TableCell>
                        </TableRow>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography variant="body1">Mandate</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table size={"small"}>
                        <TableRow>
                          <TableCell variant="head">Title (En)</TableCell>
                          <TableCell>Editorial mandate</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Title (Fr)</TableCell>
                          <TableCell>Mission éditoriale</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (En)</TableCell>
                          <TableCell>
                            Publication's editorial mandate statement
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Placeholder (Fr)</TableCell>
                          <TableCell>
                            Énoncé de la mission éditoriale de la publication
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Required</TableCell>
                          <TableCell>true</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Priority</TableCell>
                          <TableCell>1</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell variant="head">Type</TableCell>
                          <TableCell>mandate</TableCell>
                        </TableRow>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                  <Typography variant={"body1"} gutterBottom></Typography>
                  <Typography variant={"body1"}>
                    You can overwrite any of these fields in the export by using
                    the field type column.
                  </Typography>
                </Grid>
              </Grid>
              <Card sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableCell>Column Label</TableCell>
                    <TableCell>Category or Field?</TableCell>
                    <TableCell>Description</TableCell>
                  </TableHead>
                  <TableRow>
                    <TableCell variant="head">Type</TableCell>
                    <TableCell>Both</TableCell>
                    <TableCell>
                      Either <Mono>category</Mono> or <Mono>field</Mono>{" "}
                      (defaulted to <Mono>category</Mono>). Rows with type
                      "category" will create a new category for this program and
                      year, and rows with type "field" will create a input field
                      for the category in the{" "}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Title and Title (Fr)</TableCell>
                    <TableCell>Both</TableCell>
                    <TableCell>
                      The title of the category dispalyed to the user in English
                      and French.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Price</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The price a submitter should pay to submit to this
                      category. If Discounted Price is set with a date then the
                      discount will overwrite the price until the set Discount
                      End Date
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Discounted Price</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The discounted price a submitter should pay to submit to
                      this category until the Discount End Date. If the Discount
                      End Date is not set, but a Discounted Price is provided,
                      the Discounted Price will be accepted until the assigned
                      End Date.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Start Date</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The first day a user can submit entries to this category
                      (starting at <Mono>00:00:00 EST</Mono>)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">End Date</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The last day a user can submit entries to this category
                      (ending at <Mono>23:59:59 EST</Mono>)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Discount End Date</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The last day a submitter is eligble for the Discounted
                      Price (ending at <Mono>23:59:59 EST</Mono>)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Active</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      Either <Mono>true</Mono> or <Mono>false</Mono> (defaulted
                      to <Mono>true</Mono>). Use this column to indicate if the
                      category is activated (eg. visible to submitters) on
                      import.
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell variant="head">Program (Auto-set)</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The program ("Organization") of the category is
                      automatically set by the currently selected one (
                      <Mono>{organization}</Mono>)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Year (Auto-set)</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>
                      The year of the category is automatically set by the
                      currently selected one (<Mono>{year}</Mono>)
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Field Type</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>
                      One of <Mono>title</Mono>, <Mono>publication</Mono>,{" "}
                      <Mono>mandate</Mono>, <Mono>text</Mono>, or{" "}
                      <Mono>upload</Mono> (defaulted to <Mono>text</Mono>). The
                      input field presented to the submitter. Use "upload" for
                      both PDF and image responses.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Required</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>
                      Either <Mono>true</Mono> or <Mono>false</Mono> (defaulted
                      to <Mono>false</Mono>). Use this column to indicate if the
                      field is required during submission.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Category Reference</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>
                      Include the name of the cateogry in English{" "}
                      <em>exactly how it is indicated in your import</em>.
                      Fields that cannot be associated with any category will be
                      omitted.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">
                      Placeholder and Placeholder (Fr)
                    </TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>
                      The input field placeholder of the category displayed to
                      the user in English and French.
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Priority</TableCell>
                    <TableCell>Field</TableCell>
                    <TableCell>
                      An integer (0, 1, 2, ...) to indicate the order the field
                      is displayed in with lower priority value appearing first.
                    </TableCell>
                  </TableRow>
                </Table>
              </Card>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    </Box>
  );
};

// const CategoryExporter = (categories) => {
//   const exportedCategories = categories.map((category) => {
//     console.log("Exporting category", { category });
//     const out = { ...category };
//     out.id = "category-" + out.id;
//     out.date_created = out.createdAt;
//     out.date_updated = out.updatedAt;
//     out.number_of_fields = out.fields.length;
//     delete out.active;
//     delete out.fields;
//     delete out.icon;
//     // delete out.shortName;
//     delete out.org_id;

//     return out;
//   });
//   jsonExport(
//     exportedCategories,
//     {
//       headers: ["id", "title", "title_fr"],
//     },
//     (err, csv) => {
//       console.log("Running CSV Export", { err, csv });
//       const now = new Date();
//       downloadCSV(
//         csv,
//         "categories-export-" + now.toLocaleString().replace(/[^0-9a-z]/gi, "")
//       );
//     }
//   );
// };

const CategoryList = (props) => {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();

  const filter = React.useCallback(() => {
    return { year, organization };
  }, [year, organization]);

  return (
    <List
      empty={
        <EmptyCategory {...props} year={year} organization={organization} />
      }
      aside={
        <Box
          sx={{
            order: -1,
            mr: 1,
            mt: 8,
            width: 250,
          }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs>
                  <SelectYear
                    value={year}
                    callback={setYear}
                    // fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                    // fullWidth
                  />
                </Grid>
              </Grid>

              {/* <FilterLiveSearch /> */}
            </CardContent>
          </Card>
        </Box>
      }
      perPage={constants.layout.perPage}
      filter={filter()}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label={"ID"} />
        <TextField source="title" label={"Title (English)"} />
        <TextField source="title_fr" label={"Title (French)"} />
        <DateField source="start" label={"Start Date"} />
        <DateField source="end" label={"End Date"} />
        <NumberField
          source="number_of_entries"
          textAlign={"center"}
          id={"Number of Entries"}
        />
        <NumberField
          source="number_of_fields"
          textAlign={"center"}
          id={"Number of Fields"}
        />
        <FunctionField
          onClick={(e) => e.stopPropagation()}
          label={"Download Media"}
          render={(a, b, c) => {
            return (
              <Button
                size={"small"}
                startIcon={<Download />}
                href={`${constants.api.url}${a.downloadMediaLink}`}
              >
                Download
              </Button>
            );
          }}
        />
      </Datagrid>
    </List>
  );
};

export default CategoryList;
