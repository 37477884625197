import { TextField } from "@mui/material";
import React from "react";

const RecordTextField = ({
  type = "text",
  label,
  value,
  source,
  id,
  handleChange,
  error,
  withRef,
  tabIndex,
}) => {
  return (
    <>

    <TextField
    error={error && error.length > 0}
    helperText={error}
      ref={withRef}
      fullWidth
      onBlur={handleChange}
      id={[source, id].join("-")}
      defaultValue={value}
      {...{ label, type, tabIndex }}
    />
    </>
    
  )
};

export default RecordTextField;
