import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import filter from "./filter";

const COLORS = ["#CFD8DC", "#B0BEC5", "#90A4AE", "#78909C"];

const DICT = {
  freelancer: "Freelancer",
  "freelancer-unverified": "Freelancer (unverified)",
  smallMagazine: "Small Mag.",
  "smallMagazine-unverified": "Small Mag. (unverified)",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + (radius + outerRadius / 2) * Math.cos(-midAngle * RADIAN);
  const y = cy + (radius + outerRadius / 2) * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function ChartEntriesByRebate({ data }) {
  let dataEntriesByRebate = filter.entriesByRebate(data);
  if (!dataEntriesByRebate || dataEntriesByRebate.length === 0)
    return <p>No entries</p>;
  return (
    <ResponsiveContainer height={135} width={"100%"}>
      <PieChart width={10} height={10}>
        
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { name, value, payload: activePayload } = payload[0];
              const { fill } = activePayload;

              return (
                <Card elevation={1} sx={{ border: 0 }}>
                  <CardHeader
                    titleTypographyProps={{ variant: "body1" }}
                    subheaderTypographyProps={{ variant: "body2" }}
                    sx={{ backgroundColor: `${fill}88` }}
                    title={DICT[name]}
                    subheader={`${value} submission${value > 1 ? "s" : ""}`}
                  />
                </Card>
              );
            }
          }}
        />
        <Pie
          data={dataEntriesByRebate}
          fillOpacity={1}
          fill={"url(#greyFade)"}
          dataKey={"count"}
          nameKey={"rebate"}
          outerRadius={50}
          innerRadius={15}
          paddingAngle={3}
          // startAngle={-270}
          labelLine={false}
          label={renderCustomizedLabel}
          // legendType={'line'}
        >
          {data.map((el, i) => (
            <Cell
              key={`rebate-cell-${i}`}
              fill={COLORS[i % COLORS.length]}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChartEntriesByRebate;
