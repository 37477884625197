import React from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import { Save } from "@mui/icons-material";
import { useCreateContext } from "react-admin";
import useOrganization from "../../../hooks/useOrganization";
import useYear from "../../../hooks/useYear";
import formHelper from "../../../helper/form";
import CreateLayout from "../../Common/CreateLayout";
import judgeHelper from "../../../helper/judge";
import JudgeInfoCard from "../Common/JudgeInfoCard";
import JudgeCategoriesCard from "../Common/JudgeCategoriesCard";
import JudgeNewEntries from "../Common/JudgeNewEntries";
import JudgeRemoveAllNewEntriesButton from "../Common/JudgeRemoveAllNewEntriesButton";

function JudgeInfo() {
  const { save } = useCreateContext();

  const [year] = useYear();
  const [organization] = useOrganization();

  const [judge, setJudge] = React.useState(
    judgeHelper.createJudge({ organization, year, tempPass: '' })
  );
  const [error, setError] = React.useState(false);

  const handleChange = React.useCallback(
    (el, options) => {
      React.startTransition(() => {
        const key = el.target.id.split("-")[0];
        formHelper.updateState(
          { key, value: el.target.value, options },
          setJudge
        );
      });
    },
    [setJudge]
  );

  const handleSave = React.useCallback(() => {
    setError(false);
    if (
      formHelper.validate({
        data: judge,
        validationProps: judgeHelper.judgeValidation(),
        callback: setJudge,
        errorCallback: setError,
      })
    )
      save({...judgeHelper.prepareJudgeForSave(judge), organization});
  }, [judge, setJudge, save]);

  const noEntries =
    !judge || (judge.newEntries && judge.newEntries.length === 0);

  const JudgeCreateButtons = React.useCallback(() => {
    return (
      <Grid container>
        <Grid item>
          <Button
            onClick={handleSave}
            variant={"contained"}
            startIcon={<Save />}
          >
            Create Judge
          </Button>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    );
  }, [handleSave]);

  return (
    <CreateLayout {...{ error }} buttons={JudgeCreateButtons}>
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card elevation={0} sx={{ background: "#ffb74d22" }}>
                <CardContent>
                  <Typography variant={"body1"}>
                    <strong>Notice on creating judges</strong>
                  </Typography>
                  <Typography variant={"body2"}>
                    The system searches for an existing user with that email
                    and, if one is found, it does <strong>not</strong> change
                    the password. It will instead assign them a judge role and
                    the selected entries, so they may be able to use the judging
                    portal.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <JudgeInfoCard {...{ judge, handleChange }} />
            </Grid>
            <Grid item xs={12}>
              <JudgeCategoriesCard {...{ year, handleChange }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card>
            <CardHeader
              title={"Included for Review"}
              action={
                <JudgeRemoveAllNewEntriesButton entries={judge.newEntries} {...{handleChange}}/>
              }
            />
            <CardContent>
              {judge.errors && judge.errors.newEntries && (
                <Card
                  elevation={0}
                  sx={(theme) => ({
                    background: `${theme.palette.error.dark}15`,
                  })}
                >
                  <CardContent>
                    <Typography color={"error"}>
                      {judge.errors.newEntries}
                    </Typography>
                  </CardContent>
                </Card>
              )}
              {/* {JSON.stringify(judge.entries)} */}
              {judge.newEntries && (
                <JudgeNewEntries
                  entries={judge.newEntries}
                  {...{ handleChange }}
                />
              )}
              {noEntries && <p>No entries. Add some...</p>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CreateLayout>
  );
}

export default JudgeInfo;
