const validate = {
  title: (value) => {
    return value && value.trim().length > 0;
  },
  text: (value) => {
    return value && value.trim().length > 0;
  },
  email: (value) => {
    return value && value.toLowerCase().match(/^\S+@\S+\.\S+$/);
  },
  tempPass: (value) => {
    console.log("TEMP PASSWORD", { value });

    return value === null || (value && value.trim().length >= 6);
  },
  password: (value) => {
    // console.log("PASSWORD", { value, l: value.length });
    return (
      typeof value === "string" &&
      (value.trim().length === 0 || value.trim().length >= 6)
    );
  },
};

function getErrorArray(o) {
  return Object.keys(o).map((el) => o[el]);
}

const arrayHasErrors = (o) =>
  o.filter((val) => {
    if (!val || typeof val !== "string") return false;
    return val.trim().length > 0;
  }).length > 0;

//ret: Bool (true ==> errors, false ==> no error)
function checkForErrors(data, { validation = [], children = [] } = {}) {
  // console.log("checkForErrors()", { data });
  if (!data) return false;
  let { errors = {} } = data;
  errors = getErrorArray(errors);

  children &&
    children.length &&
    children.forEach((child) => {
      const { key, validation } = child;
      if (data[key]) {
        if (Array.isArray(data[key]))
          data[key].forEach((el) => {
            // console.log("CHECKING CHILD", { el, key });
            errors = [
              ...errors,
              ...validation.map((v) => {
                if (
                  el.errors &&
                  el.errors[v.prop] &&
                  el.errors[v.prop].length > 0
                )
                  return el.errors[v.prop];
              }),
            ].filter(Boolean);

            // errors = [...errors, el.errors[el.type]];
          });
        // else if (typeof data[key] === "object" && data[key] !== null)
        //   errors = [...errors, ...checkForErrors(data[key])];
      }
    });

  const ahe = arrayHasErrors(errors);
  // console.log("ahe()", { ahe, errors });
  return ahe;
}

/*
errorProps = [{
  key: String //one of: String (key from validate object), Function (custom validation)
}]
*/
function validateForm(data, { validation = [], children = [] }) {
  // console.log("validateForm()", {
  //   data,
  //   validation,
  //   children,
  // });
  const next = {
    ...data,
    errors: {},
  };

  validation &&
    validation.length &&
    validation.forEach(({ key, prop, errorLabel }) => {
      if (typeof prop === "string" && !!validate[prop]){
        if(key!=='tempPass' || (key==='tempPass' && data.setTempPass))
          next.errors[key] = validate[prop](data[key]) ? "" : errorLabel;
      }else if (typeof prop === "function")
        next.errors[key] = prop(data[key]) ? "" : errorLabel;
    });

  children &&
    children.length &&
    children.forEach((child) => {
      let { key } = child;
      if (Array.isArray(data[key])) {
        next[key] = data[key].map((o) =>
          validateForm(o, {
            validation: child.validation, //.filter((el) => el.key === o.type)
          })
        );
      } else next[key] = validateForm(next[key], { ...child });
    });

  return next;
}

const errorHelper = {
  validate,
  validateForm,
  getErrorArray,
  arrayHasErrors,
  checkForErrors,
};

export default errorHelper;
