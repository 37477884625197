import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ArrayField,
  downloadCSV,
} from "react-admin";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,

} from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";
import jsonexport from "jsonexport/dist";

const ScoreExporter = (scores) => {
  let headers = {
    category: "Category",
    title: "Title",
    language: "Language",
    creator: "Creator",
    average: "Average",
  };
  const exportedScores = scores.map((score) => {
    const out = {};
    Object.keys(headers).forEach((el) => (out[el] = score[el] && score[el]));
    score.judges.forEach((el) => {
      let found = false;
      let i = 1;
      while (!found) {
        const key = "judge" + i;        
        if (!out[key]) {
          out[key + "-name"] = el.judge;
          out[key] = el.score;
          headers[key + "-name"] = "Judge " + i + " Name";
          headers[key] = "Judge " + i +" Score";
          found = true;
        }
        ++i
      }
    });
    return out
  });
  jsonexport(
    exportedScores,
    {
      fillTopRow: true,
      headers: Object.keys(headers),
      rename: Object.values(headers),
    },
    (_, csv) => {
      const now = new Date();
      downloadCSV(
        csv,
        "scores-export-" + now.toLocaleString().replace(/[^0-9a-z]/gi, "")
      );
    }
  );
};

const ScoreList = () => {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();
  const filter = React.useCallback(() => {
    return { year, organization };
  }, [year, organization]);

  return (
    <List
    exporter={ScoreExporter}
      empty={
        <Box sx={{ mt: 8 }}>
          <Card>
            <CardHeader title="No scores found" />
            <CardContent>
              There are no scores for this year & program.
            </CardContent>
          </Card>
        </Box>
      }
      aside={
        <Box
          sx={{
            order: -1,
            mr: 1,
            mt: 8,
            width: 250,
          }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs>
                  <SelectYear
                    value={year}
                    callback={setYear}
                    // fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                    // fullWidth
                  />
                </Grid>
              </Grid>

              {/* <FilterLiveSearch /> */}
            </CardContent>
          </Card>
        </Box>
      }
      perPage={9999}
      filter={filter()}
    >
      {/* SimpleShowLayout>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography>Judge Name</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Score</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField source="score1-judge" />
              </Grid>
              <Grid item xs={4}>
                <TextField source="score1" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField source="score2-judge" />
              </Grid>
              <Grid item xs={4}>
                <TextField source="score2" />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextField source="score3-judge" />
              </Grid>
              <Grid item xs={4}>
                <TextField source="score3" />
              </Grid>
            </Grid>
            
          </SimpleShowLayout> */}
      <Datagrid
        bulkActionButtons={false}
        rowClick={false}
        isRowSelectable={false}
        optimized
        // expand={<ExpandedScore />}
      >
        <TextField source="category" label={"Category"} />
        <TextField source="id" label={"Entry ID"} />

        <TextField source="title" label={"Title"} />
        <TextField source="language" label={"Language"} />
        <TextField source="creator" label={"Creator"} />
        <ArrayField source="judges" label={"Judges"}>
          <Datagrid
          header={<></>}
            bulkActionButtons={false}
            rowClick={false}
            isRowSelectable={false}
            sx={{
              '& .RaDatagrid-row:last-child .RaDatagrid-rowCell':{
                borderBottom:'none'
              }
            }}
          >
            <TextField source="judge" label={"Judge"} />
            <NumberField source="score" label={"Score"} />
          </Datagrid>
        </ArrayField>
        {/* <ScoreColumns /> */}
        {/* <NumberField source="score1" textAlign={"center"} id={"Judge 1"} />
        <NumberField source="score2" textAlign={"center"} id={"Judge 2"} />
        <NumberField source="score3" textAlign={"center"} id={"Judge 3"} /> */}
        <NumberField source="average" textAlign={"right"} id={"Average"} />
      </Datagrid>
    </List>
  );
};

export default ScoreList;
