import React from "react";
import {
  Edit,
  TextInput,
  EditButton,
  TabbedForm,
  FormTab,
  DateInput,
  NumberInput,
  BooleanInput,
  TextField,
  Datagrid,
  ReferenceManyField,
  SimpleForm,
  DateField,
  BooleanField,
  useRecordContext,
  Button,
} from "react-admin";
// import {
//   Box,
//   Card,
//   CardHeader,
//   Typography,
//   Toolbar,
//   Tooltip,
// } from "@material-ui/core";

// import {
//   Email as EmailIcon,
//   VerifiedUser as VerifiedUserIcon,
// } from "@material-ui/icons";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

// const Field = ({ data }) => {
//   return (
//     data && (
//       <Card>
//         <CardHeader
//           title={data.title}
//           subheader={new Date(data.createdAt).toLocaleString()}
//           action={<EditButton record={data} basePath="/fields" />}
//         />
//       </Card>
//     )
//   );
// };

// const Entry = ({ data }) => {
//   return (
//     data && (
//       <Card>
//         <CardHeader
//           title={data.title}
//           subheader={`${data.publication} — ${new Date(
//             data.createdAt
//           ).toLocaleString()}`}
//           action={<EditButton record={data} basePath="/entry" />}
//         />
//       </Card>
//     )
//   );
// };

const SubmitterEditForm = () => {
  const record = useRecordContext();
  return (
    <SimpleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CardHeader
            title="Submitter Info"
            action={
              <BooleanInput
                fullWidth
                source={"active"}
                label={"Activate Submitter"}
              />
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <strong>User ID:</strong>{" "}
                    <TextField variant={"body"} source={"uuid"} />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <BooleanField variant="body2" source={"acceptTOC"} />
                        <Typography variant="label">Accepted Terms</Typography>
                      </Grid>
                      <Grid item xs>
                        <BooleanField variant="body2" source={"subscribe"} />
                        <Typography variant="label">
                          Subcribed to Marketing
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <strong>Created At:</strong>{" "}
                    <DateField variant={"body"} source={"createdAt"} showTime />
                  </Grid>
                  <Grid item xs={6}>
                    <strong>Last Updated:</strong>{" "}
                    <DateField variant={"body"} source={"updatedAt"} showTime />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <TextInput fullWidth source={"username"} label={"Username"} />
            <TextInput fullWidth source={"email"} label={"Email"} />
            <TextInput fullWidth source={"fullName"} label={"Full Name"} />
          </CardContent>
        </Grid>
        {record.entries && record.entries.length && (
          <Grid item xs={12}>
            <Card elevation={0}>
              <CardHeader title={"Entries"} />
              <CardContent>
                <Grid container spacing={2}>
                  {record.entries.map((entry) => {
                    return (
                      <Grid item xs={4} sm={4} key={entry.id}>
                        <Card>
                          <CardHeader
                      
                            action={
                              <Button href={`/#/entry/${entry.id}`}>
                                View Entry
                              </Button>
                            }
                            title={entry.title}
                            subheader={entry.uuid}
                          />
                          <CardContent>
                            <TableContainer>
                              <Table size="small">
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Publication</TableCell>
                                    <TableCell>{entry.publication || '(No publication)'}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Category</TableCell>
                                    <TableCell>{entry.category_title || '(No category)'}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Created</TableCell>
                                    <TableCell>{entry.createdAt}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Last Updated</TableCell>
                                    <TableCell>{entry.updatedAt}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Active</TableCell>
                                    <TableCell>{entry.active ? 'Yes' : 'No'}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </SimpleForm>
    // <TabbedForm>
    //   <FormTab label={"summary"}>
    //     <Typography variant={"h2"}>Edit Summary</Typography>

    //     <TextInput disabled fullWidth source="uuid" label={"User ID"} />
    //     <TextField fullWidth source="email" label={"Email"} />
    //     <TextField fullWidth source="fullName" label={"Full Name"} />
    //   </FormTab>
    //   <FormTab label={"Entries"}>
    //     <ReferenceManyField
    //       reference={"submitterEntry"}
    //       target={"submitter_id"}
    //       label={"Submitter Entries"}
    //     >
    //       <Datagrid>
    //         <TextField source="id" />
    //         <TextField source="title" label={"Entry Name"} />
    //         <EditButton record={"entry"} basePath={"/entry"} />
    //       </Datagrid>
    //     </ReferenceManyField>
    //   </FormTab>
    // </TabbedForm>
  );
};

const SubmitterActions = () => {
  return (
    <Toolbar>
      {/* <Tooltip
        title={
          data.subscribe
            ? "Subscribed to marketing"
            : "Not subscribed to marketing"
        }
      >
        <EmailIcon style={{ fill: data.subscribe ? "#000" : "#aaa" }} />
      </Tooltip> */}

      {/* <Tooltip
        title={
          data.acceptTOC
            ? "Accepted Terms and Conditions"
            : "Has not accepted Terms and Conditions"
        }
      >
        <VerifiedUserIcon style={{ fill: data.acceptTOC ? "#000" : "#ccc" }} />
      </Tooltip> */}
    </Toolbar>
  );
};

function SubmitterEdit() {
  return (
    <Edit>
      {/* actions={<SubmitterActions {...props} />} */}
      <SubmitterEditForm />
    </Edit>
  );
}

export default SubmitterEdit;
