import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import RecordTextField from "../../Common/RecordTextInput";
// import { required, SelectInput } from "react-admin";

function JudgeInfoCard({ judge, handleChange, edit = false }) {
  const JudgeFullNameField = React.useCallback(
    () => (
      <RecordTextField
        error={judge.errors && judge.errors.fullName}
        type={"text"}
        label={"Full Name"}
        value={judge.fullName}
        id={judge.id}
        source={"fullName"}
        handleChange={handleChange}
      />
    ),
    [judge, handleChange]
  );

  const JudgeEmailField = React.useCallback(
    () => (
      <RecordTextField
        error={judge.errors && judge.errors.email}
        type={"text"}
        label={"Email Address"}
        value={judge.email}
        id={judge.id}
        source={"email"}
        handleChange={handleChange}
      />
    ),
    [judge, handleChange]
  );

  const JudgeLanguageSelect = React.useCallback(
    () => (
      <React.Fragment>
        <InputLabel id={`lang-label-${judge.id}`}>Language</InputLabel>
        <Select
          labelId={`lang-label-${judge.id}`}
          id={`lang-${judge.id}`}
          value={judge.lang}
          label="Language"
          onChange={(el) =>
            handleChange({
              target: { ...el.target, id: `lang-${judge.id}` },
            })
          }
        >
          <MenuItem value={"en"}>English</MenuItem>
          <MenuItem value={"fr"}>French</MenuItem>
        </Select>
      </React.Fragment>
    ),
    [judge, handleChange]
  );

  const JudgeTempPass = React.useCallback(
    () => (
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <RecordTextField
            error={judge.errors && judge.errors.password}
            type={"text"}
            label={"Temporary Password (6 or more characters)"}
            value={judge.tempPass}
            id={judge.id}
            source={"tempPass"}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs>
          <FormGroup>
            <FormLabel>Set temporary password?</FormLabel>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>No</Typography>
              <Switch
                defaultChecked={judge.setTempPass}
                source={'setTempPass'}
                id={judge.id}
                onChange={(el) => {
                  handleChange({
                    ...el,
                    target: {
                      ...el.target,
                      value: el.target.value?.toLowerCase() === "on",
                    },
                  });
                }}
              />
              <Typography>Yes</Typography>
            </Stack>
          </FormGroup>
        </Grid>
      </Grid>
    ),
    [judge, handleChange]
  );

  const JudgePassword = React.useCallback(
    () => (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RecordTextField
            error={judge.errors && judge.errors.password}
            type={"text"}
            label={"Change Password (6 or more characters)"}
            value={judge.password}
            id={judge.id}
            source={"password"}
            handleChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Card elevation={0} sx={{ background: "#ffb74d22" }}>
            <CardContent>
              <Typography variant={"body2"}>
                Updating the judge's password will ask them to reset it on next
                login.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    ),
    [judge, handleChange]
  );

  const JudgeLanguage = React.useCallback(
    () => (
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id={"judge-lang-label"}>Language</InputLabel>
          <Select
            labelId={"judge-lang-label"}
            id={"lang"}
            value={judge.lang}
            onChange={(el) => {
              handleChange({
                target: {
                  ...el.target,
                  id: "lang",
                },
              });
            }}
            // validate={required()}
          >
            <MenuItem
              id={`lang-${judge.id}-en`}
              value={"en"}
              selected={judge.lang === "en"}
            >
              English
            </MenuItem>
            <MenuItem
              id={`lang-${judge.id}-fr`}
              value={"fr"}
              selected={judge.lang === "fr"}
            >
              French
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
    ),
    [judge, handleChange]
  );

  return (
    <Card>
      <CardHeader title={"Judge Information"} />
      <CardContent>
        <JudgeLanguage />
        <JudgeFullNameField />
        <JudgeEmailField />
        {/* {edit && <JudgePassword />} */}
        {edit && <JudgeTempPass />}
        {!edit && <JudgeLanguageSelect />}
      </CardContent>
    </Card>
  );
}

export default JudgeInfoCard;
