import React from "react"
import { Button, Card, CardContent, CardHeader, Grid, Link, Typography } from "@mui/material";
import {
  ArrayInput,
  BooleanField,
  DateField,
  EditButton,
  FormDataConsumer,
  ReferenceField,
  SimpleFormIterator,
  TextField,
} from "react-admin";
import { OpenInNew } from "@mui/icons-material";

/*

 {
            "id": 2501,
            "active": true,
            "specialConsideration": "draft",
            "payment": "unpaid",
            "createdAt": "2022-12-05T19:54:32.000Z",
            "updatedAt": "2022-12-05T20:07:15.000Z",
            "entry_id": 2501,
            "invoice_id": 544,
            "submitter_id": 635,
            "paid": false,
            "validated": false,
            "submitted": true,
            "discountApplied": false,
            "cheque": false,
            "rebate": "none",
            "winStatus": "none",
            "title": "If You're Reading This, It's Jim Joe",
            "publication": "Hazlitt",
            "year": 2022,
            "language": "en",
            "uuid": "0398-1252022-75432-HALEYC-002",
            "category_id": 398,
            "org_id": 1,
            "SubmitterEntry": {
                "id": 2501,
                "active": true,
                "specialConsideration": "draft",
                "payment": "unpaid",
                "createdAt": "2022-12-05T19:54:32.000Z",
                "updatedAt": "2022-12-05T20:07:15.000Z",
                "entry_id": 2501,
                "invoice_id": 544,
                "submitter_id": 635
            }
        },

*/


function CategoryEditEntriesTab({entries}) {
  // return JSON.stringify(entries)
  return <Grid container spacing={2}>
    {entries.filter((entry)=>entry.active).map((entry)=>{
      return <Grid item xs={12} sm={4}>
        <Card sx={{height:'100%'}}>
          <CardHeader 
          action={<Button
            target={"_blank"}
            href={`#/entry/${entry.id}`}
            size={"small"}
            color={"inherit"}
            // sx={{ height: "10px"}}
            endIcon={<OpenInNew sx={{ height: "15px" }} />}
          >
            Edit Entry
          </Button>}
          title={entry.title} titleTypographyProps={{variant:'body1'}}/>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="body2">ID: {entry.id}</Typography>
              
            </Grid>
            
            <Grid item>
              <Typography variant="body2">Publication: {entry.publication}</Typography>
              
            </Grid>
            
          </Grid>
        </CardContent>
      </Card>
      </Grid>
  })}
  </Grid>
}

export default CategoryEditEntriesTab;
