import { useState, useEffect } from "react";
import { constants } from "../config";

function useOrganization() {
  const [organization, setOrganization] = useState(
    constants.app.defaults.organization
  );

  useEffect(() => {
    const localOrganization = localStorage.getItem(
      constants.store.organization
    );
    if (localOrganization) setOrganization(localOrganization);
  }, []);

  function handleOrganizationChange(newOrganization) {
    localStorage.setItem(constants.store.organization, newOrganization);
    setOrganization(newOrganization);
  }
  return [organization, handleOrganizationChange];
}

export default useOrganization;
