import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
} from "react-admin";
import { constants } from "../../config";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import { Box, Card, CardContent, Grid } from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";


const SubmitterList = (props) => {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();
  const filter = React.useCallback(()=>{
    return ({year, organization})
  },[year, organization])
  return (
    <List
      // empty={
      //   <EmptyCategory {...props} year={year} organization={organization} />
      // }
      aside={
        <Box
          sx={{
            order: -1,
            mr: 1,
            mt: 8,
            width: 250,
          }}
        >
          
          <Card >
            
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs>
                  <SelectYear
                    value={year}
                    callback={setYear}
                    // fullWidth
                    
                  />
                </Grid>
                <Grid item xs>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                    // fullWidth
                   
                  />
                </Grid>
              </Grid>

              {/* <FilterLiveSearch /> */}
              
            </CardContent>
          </Card>
        </Box>
      }
      perPage={constants.layout.perPage}
      // exporter={CategoryExporter}
      filter={filter()}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="uuid" label={"User ID"} />
        {/* <TextField source="id" label={"Row"} /> */}
        <TextField source="email" label={"Email"} />
        <TextField source="fullName" label={"Full Name"} />
        <NumberField
          source="number_of_entries"
          textAlign={"center"}
          id={"Number of Entries"}
        />
      </Datagrid>
    </List>
  );
};

export default SubmitterList;
