import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import dateTimeHelper from "../../../helper/dateTime";
import RecordCard from "../../Common/RecordCard";
import RecordTextField from "../../Common/RecordTextInput";

const CategoryPriceCard = ({ category, firstInputRef, handleChange }) => {
  const Price = React.useCallback(
    () => (
      <RecordTextField
        type={"number"}
        label={"Full Price Cents"}
        value={category.price}
        id={category.id}
        source={"price"}
        handleChange={handleChange}
      />
    ),
    [category]
  );

  const Discount = React.useCallback(
    () => (
      <RecordTextField
        label={"Discount Price Cents"}
        value={category.discount}
        id={category.id}
        source={"discount"}
        handleChange={handleChange}
      />
    ),
    [category]
  );

  const DiscountDate = React.useCallback(
    () => (
      <RecordTextField
        type={"datetime-local"}
        label={"Last day for discount"}
        value={dateTimeHelper.convertDateToString(
          new Date(category.discountEndDate)
        )}
        id={category.id}
        source={"discountEndDate"}
        handleChange={handleChange}
      />
    ),
    [category]
  );

  return (
    <Card>
      <CardHeader title={"Price/Discount"} />
      <CardContent>
        <Price />
        <Discount />
        <DiscountDate />
      </CardContent>
    </Card>
  );
};

export default CategoryPriceCard;
