const leftPad =
  (nb = 2) =>
  (value) =>
    ("0".repeat(nb) + value).slice(-nb);
const leftPad4 = leftPad(4);
const leftPad2 = leftPad(2);


const convertDateToString = (value) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return "";
    const yyyy = leftPad4(value.getFullYear());
    const MM = leftPad2(value.getMonth() + 1);
    const dd = leftPad2(value.getDate());
    const hh = leftPad2(value.getHours());
    const mm = leftPad2(value.getMinutes());
    return `${yyyy}-${MM}-${dd}T${hh}:${mm}`;
  };



  const dateTimeHelper = {
    convertDateToString
  }

  export default dateTimeHelper