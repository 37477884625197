import { Card, CardContent, CardHeader } from "@mui/material";
import JudgeCreateAccordian from "../Accordian";

function JudgeCategoriesCard({year, handleChange}) {
  return (
    <Card>
      <CardHeader title={"Add Entries for Review"} />
      <CardContent>
        <JudgeCreateAccordian
          organization={"NMA"}
          label={"NMA Categories"}
          callback={handleChange}
          {...{ year }}
        />
        <JudgeCreateAccordian
          organization={"DPA"}
          label={"DPA Categories"}
          callback={handleChange}
          {...{ year }}
        />
        <JudgeCreateAccordian
          organization={"B2B"}
          label={"B2B Categories"}
          callback={handleChange}
          {...{ year }}
        />
      </CardContent>
    </Card>
  );
}

export default JudgeCategoriesCard;
