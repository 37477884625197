import React from "react";
import {

  useEditContext,
} from "react-admin";
import UploadInput from "./UploadInput";

function UploadFields({}) {
  const { record } = useEditContext();

  const { uploads } = record;



  return (
    uploads &&
    uploads.map(
      (upload) => <UploadInput record={upload}/>

      // <SimpleFormIterator
      //   disableAdd
      //   disableClear
      //   disableRemove
      //   disableReordering
      //   fullWidth
      //   sx={{ ".RaSimpleFormIterator-line": { border: "none" } }}
      //   // getItemLabel={i=>record.fields[i].title}
      // >
      //   <TextField source="title" />
      //   <FileField source={"value"} download title="Download File" />
      //   <FileInput
      //     source="value"
      //     label=" "
      //     fullWidth
      //     // onChange={(el)=>{
      //     //   console.log('FILE CHANGE',el)
      //     // }}
      //   />
      // </SimpleFormIterator>
    )
  );
}

export default UploadFields;
