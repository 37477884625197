import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  BooleanField,
  useRecordContext,
  Button,
} from "react-admin";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import { Box, Card, CardContent, Grid } from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";


const InvoiceList = () => {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();

  const filter = React.useCallback(() => {
    return { year, organization };
  }, [year, organization]);

  const CustomFileField = (props) => {
    const record = useRecordContext();
    const { source } = props;
    return record ? (
      record[source] ? (
        <Button rowClick={false} href={record[source]}>Download</Button>
      ) : null
    ) : null;
  };

  return (
    <List
      // aside={<InvoiceListAside />}
      // pagination={<InvoiceListPagination />}
      aside={
        <Box
          sx={{
            order: -1,
            mr: 1,
            mt: 8,
            width: 250,
          }}
        >
          
          <Card>
            
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs>
                  <SelectYear
                    value={year}
                    callback={setYear}
                    // fullWidth
                    
                  />
                </Grid>
                <Grid item xs>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                    // fullWidth
                   
                  />
                </Grid>
              </Grid>

              {/* <FilterLiveSearch /> */}
              
            </CardContent>
          </Card>
        </Box>
      }
      filter={filter()}
      perPage={99999}
      // exporter={InvoiceExporter}
    >
      <Datagrid rowClick="edit" optimized>
        <TextField source="id" label={"User ID"} />
        <NumberField source="user" label={"User"} />
        <NumberField source="fullName" label={"User Name"} />
        <NumberField source="publication" label={"Publication"} />
        <NumberField source="invoice_id" label={"ID"} />
        <NumberField source="payment_total" label={"Total"} />
        <NumberField source="amount_paid" label={"Paid"} />
        <BooleanField source="cheque" label={"Cheque"} />
        <BooleanField source="freelancer" label={"Freelancer"} />
        <CustomFileField source="pdf_link" label={"Invoice PDF Link"} />
        <CustomFileField source="summary_link" label={"Invoice Summary Link"} />
      </Datagrid>
    </List>
  );
};

export default InvoiceList;
