import { Button } from "@mui/material";
import React from "react";

function JudgeRemoveAllNewEntriesButton({ entries, handleChange }) {
  return (
    <Button
      disabled={entries.length === 0}
      size={"small"}
      color={"error"}
      // sx={{ height: "10px", mt: -0.5, mr: 1 }}
      onClick={() =>
        handleChange({
          target: { id: "removeAllNewEntries", value: null },
        })
      }
    >
      Remove {entries.length} {entries.length === 1 ? "Entry" : "Entries"}
    </Button>
  );
}

export default JudgeRemoveAllNewEntriesButton;
