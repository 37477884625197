import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { Toolbar, useGetList } from "react-admin";

import useOrganization from "../../hooks/useOrganization";
import useYear from "../../hooks/useYear";
import SelectOrganization from "../Common/SelectOrganization";
import SelectYear from "../Common/SelectYear";
import ChartEntriesByCategory from "./Chart/ChartEntriesByCategory";
import ChartEntriesByPayment from "./Chart/ChartEntriesByPayment";
import ChartEntriesByPublication from "./Chart/ChartEntriesByPublication";
import ChartEntriesByRebate from "./Chart/ChartEntriesByRebate";
import ChartEntriesBySubmitted from "./Chart/ChartEntriesBySubmitted";
import ChartEntriesPerDay from "./Chart/ChartEntriesPerDay";

function Dashboard() {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();
  const {
    data: dataEntries,
    total: totalEntries,
    isLoading: isLoadingEntries,
    error: errorEntries,
    fetch: fetchEntries,
  } = useGetList("entry", {
    pagination: { perPage: 99999, page: 1 },
    filter: { year, organization },
  });

  const categoryChartLayour = React.useCallback(() => {}, []);

  //   const dataProvider = useDataProvider();

  //   dataProvider
  //     .getList("entry", { filter: { year, organization } })
  //     .then((res) => {
  //       console.log("Get List Response", res);
  //     });

  return (
    <Paper sx={{ mt: 2, mb: 15 }}>
      <Toolbar>
        <Grid container spacing={2}>
          <Grid item>
            <SelectYear value={year} callback={setYear} />
          </Grid>
          <Grid item>
            <SelectOrganization
              value={organization}
              callback={setOrganization}
            />
          </Grid>
        </Grid>
      </Toolbar>
      <Grid container spacing={4} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <Card sx={{ pb: 0 }} elevation={0}>
                <CardHeader title="Submission data" />
                <CardContent
                  height={"100%"}
                  sx={{
                    height: "100%",
                    pb: 0,
                    mb: 0,
                    ":last-child": { pb: 0 },
                  }}
                >
                  {isLoadingEntries && (
                    <Typography variant="body1">
                      Loading submissions...
                    </Typography>
                  )}
                  {!isLoadingEntries && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm>
                            <Card sx={{ height: "100%" }} elevation={0}>
                              <CardContent>
                                <Typography variant={"label"}>
                                  Total Submissions
                                </Typography>
                                <Typography variant={"h2"}>
                                  {totalEntries}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm>
                            <Card sx={{ height: "100%" }} elevation={0}>
                              <CardContent sx={{textAlign:'center'}}>
                                <Typography variant={"label"}>
                                  Submitted
                                </Typography>
                                <ChartEntriesBySubmitted data={dataEntries} />
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm>
                            <Card sx={{ height: "100%" }} elevation={0}>
                              <CardContent sx={{textAlign:'center'}}>
                                <Typography variant={"label"}>
                                  Rebate
                                </Typography>
                                <ChartEntriesByRebate data={dataEntries} />
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12} sm>
                            <Card sx={{ height: "100%" }} elevation={0}>
                              <CardContent sx={{textAlign:'center'}}>
                                <Typography variant={"label"}>
                                  Payment
                                </Typography>
                                <ChartEntriesByPayment data={dataEntries} />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Card elevation={0} sx={{ p: 0, m: 0 }}>
                          <CardHeader
                            title={"Creation Date"}
                            titleTypographyProps={{ variant: "body1" }}
                          />
                          <CardContent
                          // sx={{ p: 0, m: 0 }}
                          >
                            <ChartEntriesPerDay data={dataEntries} />
                          </CardContent>
                        </Card>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Card elevation={0}>
                          <CardHeader
                            title={"Categories"}
                            titleTypographyProps={{ variant: "body1" }}
                          />
                          <CardContent>
                            <ChartEntriesByCategory
                              data={dataEntries}
                              layout={"vertical"}
                            />
                          </CardContent>
                        </Card>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Card elevation={0}>
                          <CardHeader
                            title={"Publications"}
                            titleTypographyProps={{ variant: "body1" }}
                          />
                          <CardContent>
                            <ChartEntriesByPublication
                              data={dataEntries}
                              layout={"vertical"}
                            />
                          </CardContent>
                        </Card>
                        <Divider />
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Dashboard;
