import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React from "react"
import useYear from "../../hooks/useYear"


function SelectYear({value, callback, fullWidth = false, sx}){
    const [year, setYear] = useYear()
    return (<FormControl sx={{width:'100%'}}>
        <InputLabel id={'year-select-label'}>Year</InputLabel>
        <Select
        fullWidth={fullWidth}
        labelId="year-select-label"
        id="year-select"
        value={value || year}
        onChange={(el)=>{
          const {value} = el.target
          callback ? callback(value) : setYear(value)
        }}
        sx={sx}
        >
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
        </Select>
      </FormControl>)
}

export default SelectYear