import React from "react";
import CreateBase from "../../Common/CreateBase";
import JudgeInfo from "./JudgeInfo";

function JudgeCreate() {

  return (
    <CreateBase>
      <JudgeInfo/>
    </CreateBase>
  );
}

export default JudgeCreate;
