import _ from "lodash";

const createJudge = ({
  uuid = "",
  id = `new-${new Date().getTime()}`,
  fullName = "",
  email = "",
  password = "",
  lang = "en",
  active = true,
  year = 0,
  organization = "NMA",
  entries = [],
  newEntries = [],
  forcePasswordReset = false,
  no_entries = 0,
  sentWelcome = false,
  tempPass = null,
  setTempPass = false,
} = {}) => ({
  uuid,
  id,
  fullName,
  email,
  tempPass,
  password,
  lang,
  year,
  organization,
  active,
  entries,
  newEntries,
  forcePasswordReset,
  no_entries,
  sentWelcome,
  setTempPass,
});

const judgeValidation = () => ({
  validation: [
    {
      key: "fullName",
      prop: "text",
      errorLabel: "Please enter a full name",
    },
    {
      key: "email",
      prop: "email",
      errorLabel: "Please provide a proper email address",
    },
    {
      key: "tempPass",
      prop: "tempPass",
      errorLabel:
        "Please enter a temporary password that is 6 or more characters long",
    },
    {
      key: "password",
      prop: "password",
      errorLabel:
        "Please enter a password that is 6 or more characters long",
    },
    // {
    //   key: "newEntries",
    //   prop: (el) => Array.isArray(el) && el.length > 0,
    //   errorLabel: "Please add at least one entry",
    // },
  ],
});

const getEntryIds = (entries) =>
  entries && entries.map((entry) => entry.id).join(",");

const prepareJudgeForSave = (judge) => {
  const ret = {...judge}
  if(judge.setTempPass && judge.tempPass && judge.tempPass.length>0) ret.password = judge.tempPass
  if(judge.newEntries && judge.newEntries.length>0) ret.entry_ids = getEntryIds(judge.newEntries)
  return ret
};

const getScoreCodeIndex = (score) => {
  if (!score) return 0; //Not Scored
  if (score < 1) return 1; //Conflict
  return 2; //scored
};

const sumScored = (entries) => {
  const scored = entries.filter((entry) => entry.score);
  return scored.length;
};

const sumEligible = (entries) => {
  const scored = entries.filter((entry) => entry.score && entry.score > 0);
  return scored.length;
};

const categoryAverage = (entries) => {
  const scores = entries.map(
    (entry) => entry.score && entry.score > 0 && entry.score
  );
  return scores ? _.sum(scores) / sumEligible(entries) : 0;
};

const groupByCategory = ({ entries, organization }) => {
  // console.log('()',{entries})
  const categories = {};
  if (entries && entries.length > 0) {
    entries
      .filter((entry) => entry.Organization.shortName === organization)
      .forEach((entry) => {
        const category = entry?.Category?.title;
        if (categories[category]) categories[category].entries.push(entry);
        else{
          const categoryEntries = entries.filter((entry)=>entry?.Category?.title===category)
          categories[category] = {
            title: category,
            average: categoryAverage(categoryEntries) || '-',
            scored: sumScored(categoryEntries),
            total: categoryEntries.length,
            entries: [entry],
          };
        }
      });
  }

  return categories;
};

const judgeHelper = {
  createJudge,
  judgeValidation,
  getEntryIds,
  prepareJudgeForSave,
  getScoreCodeIndex,
  groupByCategory,
};

export default judgeHelper;
