import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { Edit, EditBase, FormTab, TabbedForm } from "react-admin";
import CategoryEditEntriesTab from "./EntriesTab";
import CategoryEditSummaryTab from "./SummaryTab";

function TabPanel({children, value, index}){
  const hidden = value!==index
  return(
    <div
      role={'tabpanel'}
      hidden={hidden}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {!hidden && (
        <Box>
          {children}
        </Box>
      )}

    </div>
  )
}


function CategoryEdit() {
  const [tabState, setTabState] = React.useState("0");
  const [entries, setEntries] = React.useState([])
  const handleChange = (event, newValue) => {
    setTabState(newValue);
  };
  return (
    //redirect={false}
    <EditBase >
      <Box sx={{ width: "100%", mb:4 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            onChange={handleChange}
            value={tabState}
            aria-label="Create category tabs"
          >
            <Tab label="Summary" value={"0"} />
            <Tab label="Entries" value={"1"} />
          </Tabs>
        </Box>
        <TabPanel value={tabState} index={'0'}>
          <CategoryEditSummaryTab entriesCallback={setEntries} />
        </TabPanel>
        <TabPanel value={tabState} index={'1'}>
          <CategoryEditEntriesTab {...{entries}} />
        </TabPanel>
      </Box>
    </EditBase>
  );
}

export default CategoryEdit;
