import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import filter from "./filter";
import helper from "./helper";

function ChartEntriesByPublication({data}) {
  let dataEntriesByPublication = filter.entriesByPublication(data)
  // console.log('ChartEntriesByPublication',{dataEntriesByPublication})
  if(!dataEntriesByPublication || dataEntriesByPublication.length === 0) return <p>No entries</p>
  return (
    <ResponsiveContainer height={350} width={'100%'}>
      <BarChart width={'100%'} height={300} data={dataEntriesByPublication} layout={'horizontal'}>
        
        <XAxis
          tick={false}
          height={0}
          axisLine={false}
          type={'category'}
          dataKey={"publication"}
          orientation={'top'}
        />
        <YAxis
        type={'number'}
        
        axisLine={false}
          tick={true}
          tickSize={0}
          ticks={helper.generateTicks(dataEntriesByPublication, 5,2)}
          width={25}
        />
        <Tooltip />
        <CartesianGrid vertical={false} strokeDasharray={"10 3"} />
        <Bar dataKey={"count"} fillOpacity={1} fill={"url(#greyFade)"} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartEntriesByPublication;
