import React from "react";
import CategoryCreateSummary from "./Summary";
import CreateBase from "../../Common/CreateBase";

function CategoryCreate() {
  return (
    <CreateBase>
      <CategoryCreateSummary />
    </CreateBase>
  );
}

export default CategoryCreate;
