import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import filter from "./filter";

const COLORS = ["#CFD8DC", "#B0BEC5", "#90A4AE", "#78909C"];
// const COLORS = ["#FFAB91", "#FF8A65", "#FF7043", "#FF5722"];

const DICT = {
  paid: "Paid",
  unpaid: "Unpaid",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + (radius + outerRadius / 2) * Math.cos(-midAngle * RADIAN);
  const y = cy + (radius + outerRadius / 2) * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function ChartEntriesByPayment({ data }) {
  let dataEntriesByPayment = filter.entriesByPayment(data);
  // console.log('ChartEntriesByPayment()',dataEntriesByPayment)
  if (!dataEntriesByPayment || dataEntriesByPayment.length === 0)
    return <p>No entries</p>;
  return (
    <ResponsiveContainer height={135} width={"100%"}>
      <PieChart width={10} height={10}>
      
        <Tooltip
          content={({ active, payload }) => {
            if (active && payload && payload.length) {
              const { name, value, payload: activePayload } = payload[0];
              const { fill } = activePayload;
              // console.log({ active, payload })
              return (
                <Card elevation={1} sx={{ border: 0 }}>
                  <CardHeader
                    titleTypographyProps={{ variant: "body1" }}
                    subheaderTypographyProps={{ variant: "body2" }}
                    sx={{ backgroundColor: `${fill}88` }}
                    title={DICT[name]}
                    subheader={`${value} submission${value > 1 ? "s" : ""}`}
                  />
                </Card>
              );
            }
          }}
        />
        <Pie
          data={dataEntriesByPayment}
          fillOpacity={1}
          fill={"url(#greyFade)"}
          dataKey={"count"}
          nameKey={"paid"}
          outerRadius={50}
          innerRadius={15}
          paddingAngle={3}
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((el, i) => (
            <Cell key={`rebate-cell-${i}`} fill={COLORS[i % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default ChartEntriesByPayment;
