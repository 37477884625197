import { OpenInNew } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import { useEditContext } from "react-admin";
import { useDropzone } from "react-dropzone";
import uploadService from "../../../service/upload";

function UploadInput({ record }) {

  const { save, isLoading, record: prev } = useEditContext();
  const [hasValue, setHasValue] = React.useState(record?.value?.length > 0);
  const [editMode, setEditMode] = React.useState(!hasValue);


  const onDrop = React.useCallback((af) => {
    // console.log('ON DROP MEMO()',af)
    let file = af[0];
    let { name, type } = file;
    uploadService.getAdminSignedUploadUrl(
      { fileName: name, type, id: record.id },
      ({ url, signedUrl }) => {
        // console.log("getAdminSignedUploadUrl() callback()", {
        //   url,
        //   signedUrl,
        // });
        uploadService.uploadToSignedUrl({ signedUrl, file }, (res) => {
          // console.log("uploadToSignedUrl() callback()", res);
          record.value = url;
          const uploads = prev.uploads;
          const recordFieldIndex = uploads.findIndex(
            (el) => el.id === record.id
          );

          save({
            ...prev,
            uploads: [
              ...uploads.slice(0, recordFieldIndex),
              record,
              ...uploads.slice(1),
            ],
          });
          setHasValue(url?.length>0)
          setEditMode(false)
        });
      }
    );
  },[record,uploadService])

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
  } = useDropzone({
    // maxSize: Infinity,
    // minSize: 0,
    // maxFiles: 1,
    // accept: {
    //   "image/jpeg": [".jpeg", ".jpg"],
    //   "image/png": [".png"],
    //   "image/bmp": [".bmp"],
    //   "image/gif": [".gif"],
    //   "application/pdf": [".pdf"],
    // },
    onDrop,
  });

  return (
    <Paper elevation={0} sx={{ mb: 4 }}>
      <Typography variant={"body2"}>{record?.title}</Typography>
      {record?.placeholder && (
        <Typography variant={"body2"}>{record?.placeholder}</Typography>
      )}
      <ButtonGroup
        size="small"
        variant="text"
        disableElevation
        sx={{ mt: 1, mb: 1 }}
      >
        {hasValue && (
          <Button
            href={record?.value}
            target={"_blank"}
            endIcon={<OpenInNew sx={{ height: "15px" }} />}
          >
            Download File
          </Button>
        )}
        {!editMode && (
          <Button
          color={'warning'}
            onClick={() => setEditMode(true)}
            // endIcon={<OpenInNew sx={{ height: "15px" }} />}
          >
            Replace File
          </Button>
        )}
        {editMode && hasValue && (
          <Button
          color={'error'}
            onClick={() => setEditMode(false)}
            // endIcon={<OpenInNew sx={{ height: "15px" }} />}
          >
            Cancel Replace
          </Button>
        )}
        {!hasValue && <Typography color="warning" variant={'body2'}>(No File)</Typography>}
        {isLoading && (
          <Button disabled>
            <CircularProgress sx={{ height: "15px" }} />{" "}
          </Button>
        )}
      </ButtonGroup>
      {editMode && (
        <div
          {...getRootProps({
            style: {
              flex: 1,
              display: "flex",
              flexDirection: "column",
              borderRadius: "8px",
              padding: "16px",
              cursor: "pointer",
              border: isDragActive ? "2px dashed #333" : "2px dashed #eee",
            },
          })}
        >
          <p>Click or drag to upload a PDF, PNG, or JPG...</p>
          <input {...getInputProps()} />
        </div>
      )}
    </Paper>
  );
}

export default UploadInput;
