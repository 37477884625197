import React from 'react'
import CollapsableField from '../../Field/Collapsable';

const CategoryFields = ({ category, handleChange, handleReorder }) => {
    if (!category || !category.fields) return null;
    return category.fields.map((field, i) => {
      return (
        <div key={`field-${i}`}>
          <CollapsableField
            handleCategoryChange={handleChange}
            fields={category.fields}
            category_id={category.id}
            {...{ field, handleReorder }}
          />
        </div>
      );
    });
  };


  export default CategoryFields