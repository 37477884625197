import React from "react";
import Papa from "papaparse";
import { Card, CardContent, Divider, Grid } from "@mui/material";

function Restore() {
  const [data, setData] = React.useState({});

  const handleChange = (el) => {
    const id = el.target.id
    const file = el.target.files[0];
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsed = csv?.data;
      setData((prev)=>({...prev, [id]:parsed}))
    };

    reader.readAsText(file);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
       <Card>
        <CardContent>
        <p>Entry Data</p>
        <input
          id="entry"
          type="file"
          onChange={handleChange}
        />
        <Divider/>
        <p>User Data</p>
        <input
          id="user"
          type="file"
          onChange={handleChange}
        />
        <Divider/>
        <p>Submitter Entry Data</p>
        <input
          id="submitterEntry"
          type="file"
          onChange={handleChange}
        />
        <Divider/>
        <p>Invoice Data</p>
        <input
          id="invoice"
          type="file"
          onChange={handleChange}
        />
        </CardContent>
       </Card>
      </Grid>
      <Grid item xs={9}>
        <Card>
          <CardContent>{
            Object.keys(data).map((k)=><p>Key: {k}, Len: {data[k].length}</p>)
            }</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Restore;
