import ListCategory from "./List";
import CreateCategory from "./Create";
import EditCategory from "./Edit";

const Category =  {
    list: ListCategory,
    edit: EditCategory,
    create: CreateCategory,
}


export default Category;
