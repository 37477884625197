import React from "react";
import ReactDOM from "react-dom/client";
import {
  Admin,
  CustomRoutes,
  defaultTheme,
  Layout,
  Menu,
  Resource,
} from "react-admin";
import CssBaseline from "@mui/material/CssBaseline";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ArticleIcon from "@mui/icons-material/Article";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarRateIcon from "@mui/icons-material/StarRate";
import GavelIcon from "@mui/icons-material/Gavel";


import reportWebVitals from "./reportWebVitals";
import provider from "./provider/";

import Entry from "./admin/Entry";
import Category from "./admin/Category";
import Field from "./admin/Field";
import Invoice from "./admin/Invoice";
import Submitter from "./admin/Submitter";
import Judge from "./admin/Judge";
import Score from "./admin/Score";
import Dashboard from "./admin/Dashboard";
import { Box, Card, CardContent } from "@mui/material";
import Restore from "./admin/Restore";
import { Route } from "react-router-dom";

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaAppBar: {
      styleOverrides: {
        root: {
          background:
            "linear-gradient(178deg, rgba(167,9,6,1) 45%, rgba(159,8,4,1) 47%)",
          borderBottom: "1px solid #cd0000",
        },
      },
    },
    RaButton: {
      styleOverrides: {
        root: {
          color: "#a70906",
        },
      },
    },
    RaBooleanField: {
      styleOverrides: {
        root: {
          color: "#a70906",
          '[aria-label="No"]': {
            color: "#cfd8dc",
          },
          '[aria-label="Yes"]': {
            color: "#009688",
          },
        },
      },
    },
    RaResettableTextField: {
      styleOverrides: {
        root: {
          // '.Mui-focused':{
          //   borderBottom: '1px solid #a70906'
          // }
        },
      },
    },
  },
};

function CustomMenu(props) {
  return (
    <Menu {...props}>
      <Menu.DashboardItem />
      <Menu.Item
        to={"/category"}
        primaryText={"Categories"}
        leftIcon={<AppRegistrationIcon />}
      />
      <Menu.Item
        to={"/entry"}
        primaryText={"Entries"}
        leftIcon={<ArticleIcon />}
      />
      <Menu.Item
        to={"/invoice"}
        primaryText={"Invoices"}
        leftIcon={<AttachMoneyIcon />}
      />
      <Menu.Item
        to={"/submitter"}
        primaryText={"Submitters"}
        leftIcon={<AccountCircleIcon />}
      />
      <Menu.Item
        to={"/judge"}
        primaryText={"Judges"}
        leftIcon={<GavelIcon />}
      />
      <Menu.Item
        to={"/score"}
        primaryText={"Scores"}
        leftIcon={<StarRateIcon />}
      />
      {/* <Menu.Item
        to={"/restore"}
        primaryText={"Restore"}
      /> */}
    </Menu>
  );
}

function CustomLayout(props) {
  return <Layout {...props} menu={CustomMenu} />;
}
const Dialog = ({ scope }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 1900,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "#00000022",
        padding: 10
      }}
    >
      <Card>
        <CardContent>TESTING 123</CardContent>
      </Card>
    </Box>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    {/* <Dialog/> */}
    <Admin
      layout={CustomLayout}
      theme={theme}
      dataProvider={provider.dataProvider}
      authProvider={provider.authProvider}
      dashboard={Dashboard}
    >
      <Resource name={"category"} {...Category} />
      <Resource name={"entry"} {...Entry} />
      <Resource name={"field"} {...Field} />
      <Resource name={"invoice"} {...Invoice} />
      <Resource name={"submitter"} {...Submitter} />
      <Resource name={"judge"} {...Judge} />
      <Resource name={"score"} {...Score} />
      <CustomRoutes>
        <Route path={"/restore"} element={<Restore/>} />
      </CustomRoutes>
    </Admin>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
