import ListJudge from "./List";
import EditJudge from "./Edit";
import CreateJudge from "./Create";

const Judge = {
  list: ListJudge,
  edit: EditJudge,
  create: CreateJudge,
};

export default Judge;