const constants = {
    api: {
      url: process.env.REACT_APP_API_URL,
      endpoint: "/api/admin",
    },
    store: {
      year: "nmaf.store.year",
      organization: "nmaf.store.organization",
      admin: "nmaf.store.admin",
    },
    app: {
      code: process.env.REACT_APP_CODE,
      defaults: {
        organization: "NMA",
        year: 2023,
        perPage: 25,
      },
    },
    organization: {
      nma: "NMA",
      b2b: "B2B",
      dpa: "DPA",
    },
    layout: {
      perPage: 25,
    },
  };
  
  export default constants;
  