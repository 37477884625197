import React from "react";
import { Button, Grid } from "@mui/material";
import { Delete, Save } from "@mui/icons-material";
import { useEditContext, useRecordContext } from "react-admin";

import CategorySummaryTab from "../Common/SummaryTab";
import formHelper from "../../../helper/form";
import categoryHelper from "../../../helper/category";

function CategoryEditSummaryTab({entriesCallback = ()=>{}}) {
  const record = useRecordContext();
  const firstInputRef = React.useRef(null);
  const {
    save,
    // error: editError,
    isFetching,
    isLoading,
    saving,
    // resource,
    refetch,
  } = useEditContext();
  const [category, setCategory] = React.useState({ ...record });
  const [modified, setModified] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleChange = React.useCallback(
    (el) => {
      // console.log('HANFLE CHANGE',el)
      const key = el.target.id.split("-")[0];
      formHelper.updateState(
        { key, value: el.target.value, checked: el.target.checked },
        setCategory
      );
    },
    [setCategory]
  );

  const handleSave = React.useCallback(() => {
    setError(false);
    if (
      formHelper.validate({
        data: category,
        validationProps: categoryHelper.categoryValidation(),
        callback: setCategory,
        errorCallback: setError,
      })
    )
      save(category);
  }, [category, setCategory]);

  const handleReorder = ({ field_id, dir = "up" }) => {
    // console.log('handleReorder()',{ field_id, dir = "up" })
    setCategory((prev) => {
      const fields = [...prev.fields];
      const fieldIndex = fields.findIndex((f) => f.id === field_id);
      if (dir === "up")
        return {
          ...prev,
          fields: [
            ...fields.splice(0, fieldIndex - 1),
            fields.splice(1, 1),
            fields.splice(0, 1),
            ...fields,
          ],
        };
      return {
        ...prev,
        fields: [
          ...fields.splice(0, fieldIndex),
          fields.splice(1, 1),
          fields.splice(0, 1),
          ...fields,
        ],
      };
    });
  };

  React.useEffect(() => {
    console.log('USE EFFECT()',{record, entriesCallback})
    setCategory({ ...record });
    if(record && record.entries) entriesCallback(record.entries)
    if (
      firstInputRef.current &&
      typeof firstInputRef.current.value === "undefined"
    )
      refetch();
  }, [record, entriesCallback, refetch]);

  React.useEffect(() => {
    if (!isFetching && !isLoading && !isDataLoaded) {
      refetch();
      setIsDataLoaded(true);
    }
  }, [isFetching, isLoading]);

  React.useEffect(() => {
    Object.keys(category).filter((k) => {
      if (k === "fields") {
        return (
          category[k].filter((_, i) => {
            if (category[k][i]) {
              if (!record[k][i]) return true;
              return Object.keys(category[k][i]).filter((fk) => {
                return record[k][i][fk] !== category[k][i][fk];
              }).length;
            }
            return false;
          }).length > 0
        );
      }
      return record[k] !== category[k];
    }).length
      ? setModified(true)
      : setModified(false);
  }, [category]);
  
  const CategoryButtons = React.useCallback(() => {
    return (
      <Grid container>
        <Grid item>
          <Button
            disabled={!modified}
            onClick={handleSave}
            variant={"contained"}
            startIcon={<Save />}
          >
            Save Category
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          <Button
            // onClick={}
            variant={"outlined"}
            color={"error"}
            startIcon={<Delete />}
          >
            Delete Category
          </Button>
        </Grid>
      </Grid>
    );
  }, [modified, handleSave]);

  return (
    <CategorySummaryTab
      loading={!isDataLoaded}
      category={category}
      setCategory={setCategory}
      handleChange={handleChange}
      firstInputRef={firstInputRef}
      buttons={CategoryButtons}
    />
  );
}

export default CategoryEditSummaryTab;
