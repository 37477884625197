import { useState, useEffect } from "react";
import { constants } from "../config";

function useYear() {
  const [year, setYear] = useState(constants.app.defaults.year);

  useEffect(() => {
    const localYear = localStorage.getItem(constants.store.year);
    if (localYear) setYear(localYear);
  }, []);

  function handleYearChange(newYear) {
    localStorage.setItem(constants.store.year, newYear);
    setYear(newYear);
  }

  return [year, handleYearChange];
}

export default useYear;
