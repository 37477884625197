import React from "react";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import filter from "./filter";
import helper from "./helper";

function ChartEntriesByCategory({data}) {
  let dataEntriesPerByCategory = filter.entriesByCategory(data)
  // console.log("ACTIVE ENTRIES",filter.entriesByActive(data))
  // console.log('ChartEntriesByCategory',{dataEntriesPerByCategory})
  if(!dataEntriesPerByCategory || dataEntriesPerByCategory.length === 0) return <p>No entries</p>
  return (
    <ResponsiveContainer height={350} width={'100%'}>
      <BarChart width={'100%'} height={300} data={dataEntriesPerByCategory} layout={'horizontal'}>
      
        <XAxis
          tick={false}
          height={0}
          type={'category'}
          dataKey={"category.title"}
          axisLine={false}
        />
        <YAxis
        type={'number'}
        
        axisLine={false}
          tick={true}
          tickSize={0}
          ticks={helper.generateTicks(dataEntriesPerByCategory,5,2)}
          width={25}
        />
        <Tooltip />
        <CartesianGrid vertical={false} strokeDasharray={"10 3"} />

        <Bar dataKey={"count"} fillOpacity={1} fill={"url(#greyFade)"} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartEntriesByCategory;
