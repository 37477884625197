import React from "react";
import {
  Datagrid,
  TextField,
  DateField,
  downloadCSV,
  BooleanField,
  FilterLiveSearch,
  ListBase,
  ExportButton,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import {
  Card,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";

const EntryExporter = (entries) => {
  // console.log('export',{len:entries.length, entries})
  let headers = "id,category.title,createdAt,title,publication,submitted,user.fullName,fields.title,fields.value,rebate,updatedAt,cheque,has_invoice".split(
    ","
  )

  let rename = "Entry ID,Category Title,Date Created,Title,Publication,Submitted?,Submitted By,Field,Value,Rebate,Date Last Updated,Paid by Cheque?,Has Invoice?".split(
    ","
  )
  const booleanOut = (val) => (val ? "Yes" : "No");

  const exportedEntries = entries.map((entry) => {
    const out = { ...entry };


    out.submitted = booleanOut(out.submitted);
    out.cheque = booleanOut(out.cheque);
    out.has_invoice = booleanOut(out.has_invoice);
    out.rebate =
      out.rebate && out.rebate.length > 0 ? out.rebate : "(No rebate)";
    

    out.fields.forEach((field) => {
      const key = field.title;
      const value  = field.value
      
      out[key] = value;
      if(headers.indexOf(key)<0){
        headers.push(key)
        rename.push(key)
      }
      
    });

    delete out.fields;

    return out;
  });
  jsonExport(
    exportedEntries,
    {
      fillTopRow: true,
      fillGaps: true,
      headers,
      rename,
    },
    (_, csv) => {
      const now = new Date();
      downloadCSV(
        csv,
        "entries-export-" + now.toLocaleString().replace(/[^0-9a-z]/gi, "")
      );
    }
  );
};

const EntryList = () => {
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();

  const filter = React.useCallback(() => {
    return { year, organization };
  }, [year, organization]);

  return (
    
    <ListBase
      pagination={false}
      perPage={999999}
      filter={filter()}
      exporter={EntryExporter}
    >
      <Grid container spacing={2} sx={{ pt: 2 }}>
        <Grid item xs={12} sm={2}>
          <Card
            sx={{
              width: "100%",
            }}
          >
            <CardContent>
              <Grid container rowSpacing={1}>
                <Grid item xs={12}>
                <ExportButton maxResults={99999} label={`Export ${organization} Entries`} fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <FilterLiveSearch />
                </Grid>
                <Grid item>
                  <Divider sx={{ ml: 2, mr: 2 }} />
                </Grid>
                <Grid item xs={12}>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                  />
                </Grid>

                <Grid item xs={12}>
                  <SelectYear
                    value={year}
                    callback={setYear}
                  />
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Card>
            <CardContent>
              <Datagrid rowClick="edit" optimized>
                <DateField source="createdAt" label="Date Created" />
                <TextField source="user.fullName" label={"Submitted by"} />
                <TextField source="language" label={"Language"} />
                <TextField source="title" />
                <TextField source="publication" />
                <TextField source="category.title" />
                <BooleanField source="submitted" textAlign="center" />
                <BooleanField source="cheque" textAlign="center" />
                <BooleanField source="has_invoice" label="Invoiced" />
                <TextField source="rebate" />
                <TextField source="winStatus" />
              </Datagrid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ListBase>
    // </List>
  );
};

export default EntryList;
