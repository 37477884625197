import React from "react";
import { Card, CardHeader, Grid, Toolbar } from "@mui/material";
import { Warning } from "@mui/icons-material";

function CreateLayout({ error, errorLabel, children, buttons, loading }) {
  // console.log("CREATE LAYOUT()",{error})
  const ButtonBar = React.useCallback(() => {
    return (
      buttons && (
        <Grid item xs={12}>
          <Toolbar>{buttons()}</Toolbar>
        </Grid>
      )
    );
  }, [buttons]);

  const ErrorCard = React.useCallback(() => {
    return (
      error && (
        <Grid item xs={12}>
          <Card
            elevation={0}
            sx={(theme) => ({ background: `${theme.palette.error.dark}15` })}
          >
            <CardHeader
              avatar={
                <Warning
                  sx={(theme) => ({ color: `${theme.palette.error.dark}88` })}
                />
              }
              title={errorLabel}
              titleTypographyProps={{ variant: "body1" }}
              sx={(theme) => ({ color: `${theme.palette.error.dark}FF` })}
            />
          </Card>
        </Grid>
      )
    );
  }, [error, errorLabel]);
  
  if (loading) return <>Loading...</>;
  return (
    <Grid container spacing={1}>
      <ButtonBar />
      <ErrorCard />
      <Grid item xs={12}>
        {children}
      </Grid>
      <ButtonBar />
    </Grid>
  );
}

export default CreateLayout;
