import fieldHelper from "./field";

const DEFAULT_DATE = new Date().toString();

const createCategory = ({
  title = "",
  placeholder = "",
  title_fr = "",
  placeholder_fr = "",
  price = 0,
  discount = 0,
  discountEndDate = DEFAULT_DATE,
  start = DEFAULT_DATE,
  end = DEFAULT_DATE,
  active = false,
  fields = fieldHelper.defaultFields(),
  organization = '',
  year = 0,
} = {}) => ({
  title,
  placeholder,
  title_fr,
  placeholder_fr,
  price,
  discount,
  discountEndDate,
  start,
  end,
  active,
  fields,
  organization,
  year,
});

const categoryValidation = () => ({
  validation: [
    {
      key: "title",
      prop: "title",
      errorLabel: "Please enter a category label",
    },
  ],
  children: [
    {
      key: "fields",
      validation: [
        {
          key: "title",
          prop: "title",
          errorLabel: "Please enter a field label",
        },
      ],
    },
  ],
});

const categoryHelper = {
  createCategory,
  categoryValidation,
};

export default categoryHelper;
