function addToCount(o, p, v) {
  // console.log('addToCount()',{o, p, v})
  let ret = [...o];
  v = v.trim();
  const foundIdx = o.findIndex((el) => el[p] === v);
  if (foundIdx < 0) ret.push({ [p]: v && v.trim(), count: 1 });
  else {
    const copy = [...ret];
    ret = [
      ...copy.splice(0, foundIdx),
      { ...ret[foundIdx], count: ret[foundIdx].count + 1 },
      ...copy.splice(1),
    ];
  }
  return ret;
}

function generateFilter(
  data = [],
  p,
  options = { transform: null, sort: null, filter: null }
) {
  let ret = [];
  let { transform, sort, filter } = options;
  data &&
    data.forEach((el) => {
      
      let v = p.indexOf('.')>0?el[p.split('.')[0]][p.split('.')[1]] : el[p];
      // console.log('generateFilter',{v,p})
      if (transform) v = transform(v);
      ret = addToCount(ret, p, v);
    });
  if (ret.length === 0) return ret;
  if (sort) ret = ret.sort(sort);
  if (filter) ret = ret.filter(filter);
  return ret;
}

const filter = {
  entriesPerDay: (data) =>
    data &&
    generateFilter(data, "createdAt", {
      transform: (value) => {
        const date = new Date(value);
        return date.toLocaleDateString();
      },
      sort: (a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1),
    }),
  entriesByRebate: (data) =>
    data &&
    generateFilter(
      data.filter((el) => el.submitted),
      "rebate",
      {
        filter: (el) => el.rebate !== "none",
        sort: (a, b) => (a.rebate > b.rebate ? 1 : -1),
      }
    ),
  entriesByPayment: (data) =>
    data &&
    generateFilter(
      data.filter((el) => el.submitted),
      "paid",
      {
        transform: (value) => (value ? "paid" : "unpaid"),
        sort: (a, b) => (a.count > b.count ? 1 : -1),
      }
    ),
  entriesByCategory: (data) =>
    data &&
    generateFilter(
      data.filter((el) => el.submitted),
      "category.title",
      {
        sort: (a, b) => (a?.category?.title > b?.category?.title ? 1 : -1),
      }
    ),
  entriesByPublication: (data) =>
    data &&
    generateFilter(
      data.filter((el) => el.submitted),
      "publication",
      {
        transform: (value) => (value ? value : "(No publication)"),
        sort: (a, b) => (a.publication > b.publication ? 1 : -1),
      }
    ),
  entriesByActive: (data) =>
    data &&
    generateFilter(data, "active", {
      transform: (value) => (value ? "Y" : "N"),
      sort: (a, b) => (a.count > b.count ? 1 : -1),
    }),
  entriesBySubmitted: (data) =>
    data &&
    generateFilter(data, "submitted", {
      transform: (value) => (value ? "Submitted" : "Draft"),
      sort: (a, b) => (a.count > b.count ? 1 : -1),
    }),
};

export default filter;
