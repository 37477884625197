import { fetchUtils } from "react-admin";
import queryString from "query-string";
import { constants } from "../config";
import service from "../service";

const httpClient = fetchUtils.fetchJson;

const baseUrl = [constants.api.url, constants.api.endpoint].join("");

const dataProvider = {
  getList: (resource, params) => {
    // console.log("GET LIST()", { resource, params });
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      limit: perPage,
      offset: page ? (Math.max(page, 1) - 1) * perPage : 0,
      filter: JSON.stringify(params.filter),
    };

    if (field) {
      query.sort = JSON.stringify([field, order]);
      query.sortBy = field;
      query.sortDir = order;
    }

    const url = `${baseUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => {
      // console.log("getList() callback:", { json });
      return {
        data: json,
        total: parseInt(headers.get("X-Total-Count"), 10),
      };
    });
  },
  getOne: (resource, params) => {
    // console.log("GET ONE()", { resource, params });
    const { id } = params;
    const url = `${baseUrl}/${resource}/${id}`;
    return httpClient(url).then(({ json }) => {
      // console.log("Get One()", json);
      return {
        data: json,
      };
    });
  },
  getMany: (resource, params) => {
    // console.log("GET MANY()", { resource, params });
    const { ids } = params;
    const url = `${baseUrl}/${resource}`;

    const requests = ids.map((id) => httpClient(url + `/${id}`));
    return Promise.all(requests).then((res) => {
      const data = res.map((res) => res.json);
      const included = res.map((res) => res.json.included).flat(1);
      return {
        data,
        included,
      };
    });
  },
  getManyReference: (resource, params) => {
    // console.log("GET MANY REFERENCE()", { resource, params });
    const { id, target, organization, filter = {} } = params;
    const query = {
      filter: JSON.stringify({ [target]: id, ...filter }),
      organization,
    };
    const url = `${baseUrl}/${resource}?${queryString.stringify(query)}`;
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json,
        total: parseInt(headers.get("X-Total-Count"), 10),
      };
    });
  },
  create: (resource, params) => {
    // console.log("CREATE()", { resource, params });
    const { data } = params;
    const query = {};
    if (data.category_id) query.category = data.category_id;
    if (data.organization) query.org = data.organization;

    const url = `${baseUrl}/${resource}?${queryString.stringify(query)}`;

    return httpClient(url, {
      method: "POST",
      body: JSON.stringify({ data }),
    }).then(({ headers, json }) => {
      // console.log("CREATE RESPONSE", { headers, json });
      return { data: json };
    });
  },
  update: async (resource, params) => {
    // console.log("UPDATE()", { resource, params });
    const { id, data } = params;
    const doUpdate = (data) => {
      try {
        return httpClient(`${baseUrl}/${resource}/${id}`, {
          method: "PATCH",
          body: JSON.stringify({ data }),
          limit: "50mb",
          extended: true,
        }).then(({ json }) => {
          // console.log("RETURNING UPDATE", json);
          return {
            data: json,
          };
        });
      } catch (e) {
        // console.log("DO UPDATE() ERROR", e);
      }
    };

    // if (data.uploads) {
    //   const uploadedToSignedUrls = data.uploads.map(async (upload) => {
    //     console.log('dataProvider() Uploading... ',{upload})
    //     if (upload.value && upload.value.rawFile instanceof File) {
    //       httpClient(`${baseUrl}/upload/`, {
    //         method: "POST",
    //         body: JSON.stringify({
    //           data: { id: upload.id, file: upload.value },
    //         }),
    //       }).then((res) => {
    //         console.log('UPLOAD CALLBACK 1()',{res})
    //         const { signedUrl, url } = res.json;
    //         return service.upload.uploadToSignedUrl(
    //           {
    //             signedUrl,
    //             file: data.file.rawFile,
    //           },
    //           (res) => {
    //             console.log('UPLOAD CALLBACK 2()',{res})
    //             if (res.status === 200) {
    //               const uploadIdx = data.uploads.findIndex(
    //                 (o) => o.id === upload.id
    //               );
    //               data.uploads[uploadIdx].value = url;
    //             }
    //           }
    //         );
    //       });
    //     }
    //   });
    //   await Promise.all(uploadedToSignedUrls);
    // }

    return doUpdate(data);
  },
  updateMany: (resource, params) => {
    // console.log("UPDATE MANY", { resource, params });
  },
  delete: (resource, params) => {
    // console.log("DELETE", { resource, params });
    const { id } = params;
    return httpClient(`${baseUrl}/${resource}/${id}`, {
      method: "DELETE",
    }).then((results) => ({
      data: results.data,
    }));
  },
  createMany: (resource, params) => {
    // console.log("Creating Many...", { resource, params });
    switch (resource) {
      case "judge":
        const judgeRequests = params.data.map((data) => {
          const query = { org: data.organization };
          return httpClient(
            `${baseUrl}/${resource}?${queryString.stringify(query)}`,
            {
              method: "POST",
              body: JSON.stringify({
                data,
              }),
            }
          );
        });

        // console.log("judgeRequests...", judgeRequests);
        Promise.all(judgeRequests).then((res) => {
          // console.log("PROMISE THEN()", res);
          return { data: res };
        });

      case "category":
        try {
          const categoryRequests = params.data.category.map((data) => {
            return httpClient(
              `${baseUrl}/${resource}?${queryString.stringify({
                org: data.organization,
              })}`,
              {
                method: "POST",
                body: JSON.stringify({ data }),
              }
            );
          });

          Promise.all(categoryRequests).then((categoryResponse) => {
            // console.log('>>> Promise.all(categoryRequests).then',categoryResponse)
            const fieldRequests = params.data.field.map((field) => {
              const category = categoryResponse.find(
                (c) => c.json.title === field.category_ref
              );

              // console.log("FOUND FIELD CATEGORY",category)
              if (category.status === 200) {
                field.category_id = category.json.id;
                return httpClient(
                  `${baseUrl}/field?${queryString.stringify({
                    category: field.category_id,
                  })}`,
                  {
                    method: "POST",
                    body: JSON.stringify({ data: field }),
                  }
                );
              }
            });

            // console.log('>>>fieldRequests',fieldRequests)

            Promise.all(fieldRequests).then((res) => {
              // console.log("PROMISE THEN()", res);

              httpClient(`${baseUrl}/category/resetCategoryFieldPriority/${categoryResponse[0].json.year}`)
              return { data: categoryResponse };
            });
          });

          // console.log('END>>>>')
        } catch (e) {
          console.log("could not create many for categories", e.message);
        }
      default:
        return;
    }
  },
  deleteMany: (resource, params) => {
    // console.log("DELETE MANY", { resource, params });
    const { ids } = params;
    const deleteRequests = ids.map((id) =>
      httpClient(`${baseUrl}/${resource}/${id}`, {
        method: "DELETE",
      })
    );
    return Promise.all(deleteRequests).then((deleteResponses) => {
      const data = deleteResponses.map((res) => res.json);
      return { data };
    });
  },
};

export default dataProvider;
