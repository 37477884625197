import { Save } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import React from "react";
import {
  useCreateContext,
} from "react-admin";
import useOrganization from "../../../hooks/useOrganization";
import useYear from "../../../hooks/useYear";
import categoryHelper from "../../../helper/category";
import formHelper from "../../../helper/form";
import CategorySummaryTab from "../Common/SummaryTab";

function CategoryCreateSummary() {
  const { save, saving } = useCreateContext();

  const [year] = useYear();
  const [organization] = useOrganization();

  const [category, setCategory] = React.useState(
    categoryHelper.createCategory({ organization, year })
  );
  const [error, setError] = React.useState(false);

  const handleChange = React.useCallback(
    (el) => {
      const key = el.target.id.split("-")[0];
      formHelper.updateState(
        { key, value: el.target.value },
        setCategory
      );
    },
    [setCategory]
  );

  const handleSave = React.useCallback(() => {
    setError(false);
    if (
      formHelper.validate({
        data: category,
        validationProps: categoryHelper.categoryValidation(),
        callback: setCategory,
        errorCallback: setError,
      })
    )
      save(category);
  }, [category, setCategory]);

  const CategoryCreateButtons = React.useCallback(() => {
    return (
      <Grid container>
        <Grid item>
          <Button
            onClick={handleSave}
            variant={"contained"}
            startIcon={<Save />}
          >
            Create Category
          </Button>
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    );
  }, [handleSave]);

  return (
    <CategorySummaryTab
      buttons={CategoryCreateButtons}
      {...{ category, handleChange, setCategory }}
    />
  );
}

export default CategoryCreateSummary;
