const helper = {
     generateTicks: (data, width, buffer = 3) => {
        const ret = [];
        const copy = [...data];
        const max = copy.sort((a, b) => (a.count > b.count ? -1 : 1))[0].count;
      
        for (let i = 0; i < Math.floor(max / width) + buffer; i++) {
          ret.push(i * width);
        }
        return ret;
      },
}

export default helper