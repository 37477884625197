import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
} from "@mui/material";
import AccordianCategories from "./AccordionCategories";
import { Add, ExpandMore } from "@mui/icons-material";

function JudgeCreateAccordian({ year, label, organization, callback }) {
  const [loading, setLoading] = React.useState(true);
  const [entries, setEntries] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary expandIcon={<ExpandMore onClick={toggleExpanded} />} sx={{background:'#f3e5f522'}}>
        <Grid container spacing={1}>
          <Grid item>{loading ? <CircularProgress size={20} onClick={toggleExpanded} /> : <Add onClick={toggleExpanded} />}</Grid>
          <Grid item xs onClick={toggleExpanded}>
            {label}
          </Grid>
          <Grid item sx={{height:'45px'}}>
            <Button
              sx={{ height: "40px" }}
              // variant="text"
              disabled={entries.length===0}
                size={"small"}
              onClick={() =>
                callback({ target: { id: "newEntries", value: entries } },{organization})
              }
            >
              Add all in Program {organization}
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <AccordianCategories
          loadingCallback={setLoading}
          entriesCallback={setEntries}
          {...{ year, organization, callback }}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default JudgeCreateAccordian;
