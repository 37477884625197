import { OpenInNew } from "@mui/icons-material";
import {
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";

function AccordianCategoryEntries({ data, callback }) {
  return (
    <>
      {data &&
        data
          // .filter(a=>a.paid)
          .sort((a, b) => (a > b ? 1 : -1))
          .map((entry) => {
            return (
              <Paper
                elevation={0}
                sx={{ pt: 1, pb: 1, borderBottom: "1px solid #eee" }}
              >
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography variant="body2">({entry.id})</Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      {entry.title || "(No title)"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      target={"_blank"}
                      href={`#/entry/${entry.id}`}
                      size={"small"}
                      color={"inherit"}
                      sx={{ height: "10px", mt: -0.5, mr: 1 }}
                      endIcon={<OpenInNew sx={{ height: "15px" }} />}
                    >
                      Edit Entry
                    </Button>
                    <Button
                      onClick={() =>
                        callback({
                          target: { id: "newEntries", value: entry },
                        },{})
                      }
                      size={"small"}
                      sx={{ height: "10px", mt: -0.5 }}
                    >
                      Add Entry
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
    </>
  );
}

export default AccordianCategoryEntries;
