import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import filter from "./filter";
import helper from "./helper";



function ChartEntriesPerDay({ data }) {
  let dataEntriesPerDay = filter.entriesPerDay(data);
  // console.log("ChartEntriesPerDay", {
  //   data,
  //   dataEntriesPerDay,
  //   check: !dataEntriesPerDay || dataEntriesPerDay.length === 0,
  // });
  if (!dataEntriesPerDay || dataEntriesPerDay.length === 0)
    return <p>No entries</p>;
  return (
    <ResponsiveContainer height={200} width={"100%"}>
      <BarChart width={"100%"} height={200} data={dataEntriesPerDay}>
        <defs>
          <linearGradient id="greyFade" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#78909C" stopOpacity={0.9} />
            <stop offset="95%" stopColor="#78909C" stopOpacity={0.66} />
          </linearGradient>
        </defs>
        <XAxis axisLine={false} tick={false} height={0} dataKey={"createdAt"} />
        <YAxis
          axisLine={false}
          tick={true}
          tickSize={0}
          ticks={helper.generateTicks(dataEntriesPerDay, 5,3)}
          width={25}
        />

        <Tooltip />
        <CartesianGrid vertical={false} strokeDasharray={"10 3"} />

        <Bar dataKey={"count"} fillOpacity={1} fill={"url(#greyFade)"} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ChartEntriesPerDay;
