import { Card, CardContent, CardHeader } from "@mui/material";
import React from "react"



const RecordCard = ({ title, children }) => (
    <Card elevation={0}>
      <CardHeader
        {...{title}}
        sx={{ pt: 1, pb: 0 }}
        titleTypographyProps={{ variant: "body2" }}
      />
      <CardContent sx={{ pt: 0, pb: 1, mt: 0 }}>{children}</CardContent>
    </Card>
  );


  export default RecordCard