import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import React from "react"
import useOrganization from "../../hooks/useOrganization"


function SelectOrganization({value, callback, fullWidth = false, sx}){
    const [organization, setOrganization] = useOrganization()
    return (<FormControl sx={{width:'100%'}}>
        <InputLabel id={'organization-select-label'}>Program</InputLabel>
        <Select
        // autoWidth
        labelId="organization-select-label"
        id="organization-select"
        value={value || organization}
        onChange={(el)=>{
          const {value} = el.target
          callback ? callback(value) : setOrganization(value)
        }}
        sx={sx}
        >
          <MenuItem value={'NMA'}>NMA</MenuItem>
          <MenuItem value={'DPA'}>DPA</MenuItem>
          <MenuItem value={'B2B'}>B2B</MenuItem>
        </Select>
      </FormControl>)
}

export default SelectOrganization