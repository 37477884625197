import { Check, Clear, Delete, ExpandMore, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { useDelete, useEditContext, useRecordContext } from "react-admin";
import { useNavigate } from "react-router-dom";
import formHelper from "../../../helper/form";
import judgeHelper from "../../../helper/judge";
import useYear from "../../../hooks/useYear";
import emailService from "../../../service/email";
import CreateLayout from "../../Common/CreateLayout";
import RecordCard from "../../Common/RecordCard";
import JudgeCategoriesCard from "../Common/JudgeCategoriesCard";
import JudgeInfoCard from "../Common/JudgeInfoCard";
import JudgeNewEntries from "../Common/JudgeNewEntries";
import JudgeRemoveAllNewEntriesButton from "../Common/JudgeRemoveAllNewEntriesButton";
import AccordionJudgeScore from "./AccordionJudgeScore";

function JudgeEditInfo() {
  const record = useRecordContext();
  const [year] = useYear();
  const navigate = useNavigate();
  let {
    save,
    saving: savingEdit,
    isFetching,
    isLoading,
    refetch,
    resource,
  } = useEditContext();
  const [deleteOne, { isLoading: isLoadingDelete, error: errorDelete }] =
    useDelete(
      "judge",
      {
        // id: record.id,
        // previousData: record
      },
      {
        onSuccess: () => {
          navigate("/judge");
        },
      }
    );
  const [modified, setModified] = React.useState(false);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);
  const [judge, setJudge] = React.useState({ ...record, tempPass: null, setTempPass: false });
  const [saving, setSaving] = React.useState(savingEdit);

  const handleChange = React.useCallback(
    (el) => {
      const key = el.target.id.split("-")[0];
      formHelper.updateState({ key, value: el.target.value }, setJudge);
    },
    [setJudge]
  );

  const handleJudgeEmailClick = React.useCallback(() => {
    setSaving(true);
    emailService.sendJudgeEmail({ id: judge.id }, ({ json }) => {
      if (json) {
        const { sentWelcome } = json;
        sentWelcome !== judge.sentWelcome &&
          handleChange({ target: { id: "sentWelcome", value: sentWelcome } });
      }
      setSaving(false);
    });
  }, [judge]);

  // Memoize the modified check to prevent unnecessary re-renders
  const checkModified = React.useCallback(() => {
    return Object.keys(judge).some(k => record && record[k] !== judge[k]);
  }, [judge, record]);

  React.useEffect(() => {
    setModified(checkModified());
  }, [judge, checkModified]);

  const ScoreSection = React.memo(({ entries, organization, handleChange }) => {
    const categories = judgeHelper.groupByCategory({ entries, organization });
    const categoryTitles = Object.keys(categories);
    return (
      <RecordCard title={`${organization} Scores`}>
        {!categoryTitles.length ? (
          <Typography variant={"body1"}>
            There are no scores for this program
          </Typography>
        ) : (
          categoryTitles.map((category) => {
            category = categories[category];
            return (
              <Card>
                <CardContent>
                  <React.Fragment key={"test-" + category.title}>
                    <Grid container spacing={2} sx={{ mb: 2 }}>
                      <Grid item xs>
                        <Typography variant={"body1"}>
                          {category.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={"body2"}>Avg:</Typography>
                        <Typography variant={"body1"}>
                          {category.average}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant={"body2"}>Scored:</Typography>
                        <Typography variant={"body1"}>
                          {category.scored} of {category.total}
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={(category.scored / category.total) * 100}
                        />
                      </Grid>
                    </Grid>
                    {category.entries.map((entry) => (
                      <AccordionJudgeScore
                        key={`judge-score-${entry.id}`}
                        {...{ entry, handleChange }}
                      />
                    ))}
                  </React.Fragment>
                </CardContent>
              </Card>
            );
          })
        )}
      </RecordCard>
    );
  });

  const handleSave = React.useCallback(() => {
    setSaving(true);
    if (
      formHelper.validate({
        data: judge,
        validationProps: judgeHelper.judgeValidation(),
        callback: setJudge,
        errorCallback: () => {},
      })
    )
      save(judgeHelper.prepareJudgeForSave(judge));
  }, [judge, setJudge]);

  const handleDelete = React.useCallback(() => {
    deleteOne(resource, {
      id: record.id,
      previousData: record,
    });
  }, [resource, record]);

  React.useEffect(() => {
    record && setJudge(judgeHelper.createJudge({ ...record }));
  }, [record]);

  // Only fetch data once when component mounts
  React.useEffect(() => {
    if (!isFetching && !isLoading && !isDataLoaded) {
      refetch();
      setIsDataLoaded(true);
    }
  }, [isFetching, isLoading]);

  React.useEffect(() => {
    if (savingEdit !== saving) setSaving(savingEdit);
  }, [savingEdit]);

  const JudgeButtons = React.memo(({ modified, saving, handleSave, handleDelete, isLoadingDelete }) => {
    return (
      <Grid container>
        <Grid item>
          <Button
            disabled={!modified || saving}
            onClick={handleSave}
            variant={"contained"}
            startIcon={<Save />}
          >
            Save Judge
          </Button>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          <Button
            onClick={handleDelete}
            disabled={saving || isLoadingDelete}
            variant={"outlined"}
            color={"error"}
            startIcon={<Delete />}
          >
            Delete Judge
          </Button>
        </Grid>
      </Grid>
    );
  });

  // Memoize filtered entries
  const currentYear = React.useMemo(() => 
    judge?.entries?.filter(entry => entry.year.toString() === year.toString()),
    [judge?.entries, year]
  );

  const previousYears = React.useMemo(() =>
    judge?.entries?.filter(entry => entry.year.toString() !== year.toString()),
    [judge?.entries, year]
  );

  return (
    <CreateLayout buttons={<JudgeButtons 
      modified={modified}
      saving={saving}
      handleSave={handleSave}
      handleDelete={handleDelete}
      isLoadingDelete={isLoadingDelete}
    />} saving={saving}>
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={0} variant={"outlined"}>
                <CardContent sx={{ pb: 0, "&:last-child": { pb: 2 } }}>
                  <Grid container>
                    <Grid item sx={{ pt: 1 }}>
                      {judge.sentWelcome ? (
                        <Check color="success" />
                      ) : (
                        <Clear color="error" />
                      )}
                    </Grid>
                    <Grid item xs sx={{ pt: 1.25 }}>
                      <Typography variant="body2">
                        Judge Welcome Email {!judge.sentWelcome && <>Not </>}
                        Sent
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button disabled={saving} onClick={handleJudgeEmailClick}>
                        {!judge.sentWelcome ? "Send" : "Resend"} Email
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <JudgeInfoCard {...{ judge, handleChange }} edit />
            </Grid>

            <Grid item xs={12}>
              <JudgeCategoriesCard {...{ year, handleChange }} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Grid container spacing={2}>
            {judge.newEntries && judge.newEntries.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="New Entries for Review"
                    action={
                      <JudgeRemoveAllNewEntriesButton
                        entries={judge.newEntries}
                        {...{ handleChange }}
                      />
                    }
                  />
                  <CardContent>
                    <Accordion>
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        Adding {judge.newEntries.length} New{" "}
                        {judge.newEntries.length === 1 ? "Entry" : "Entries"}
                      </AccordionSummary>
                      <AccordionDetails>
                        <JudgeNewEntries
                          entries={judge.newEntries}
                          {...{ handleChange }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {judge.entries && judge.entries.length > 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardHeader title="Judge Scores" />
                  <CardContent>
                    <Typography variant="body2">
                      {year.toString()} Awards Year
                    </Typography>

                    <ScoreSection
                      entries={currentYear}
                      organization={"NMA"}
                      handleChange={handleChange}
                    />
                    <ScoreSection
                      entries={currentYear}
                      organization={"DPA"}
                      handleChange={handleChange}
                    />
                    <ScoreSection
                      entries={currentYear}
                      organization={"B2B"}
                      handleChange={handleChange}
                    />

                    {previousYears.length > 0 && (
                      <>
                        <Divider
                          sx={{
                            m2: 4,
                            mb: 4,
                          }}
                        />
                        <Typography variant="body2">
                          Other Award Years(s)
                        </Typography>

                        <ScoreSection
                          entries={previousYears}
                          organization={"NMA"}
                          handleChange={handleChange}
                        />
                        <ScoreSection
                          entries={previousYears}
                          organization={"DPA"}
                          handleChange={handleChange}
                        />
                        <ScoreSection
                          entries={previousYears}
                          organization={"B2B"}
                          handleChange={handleChange}
                        />
                      </>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </CreateLayout>
  );
}

export default JudgeEditInfo;
