import { ExpandMore, FiberNew, RemoveCircleOutline } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useDelete } from "react-admin";
import errorHelper from "../../helper/error";
import fieldHelper from "../../helper/field";

const FieldCardInputGroup = ({ title, children }) => (
  <Card
    elevation={0}
    sx={(theme) => ({ background: `${theme.palette.primary.dark}08` })}
  >
    <CardHeader
      title={title}
      sx={{ pt: 1, pb: 0 }}
      titleTypographyProps={{ variant: "body2" }}
    />
    <CardContent sx={{ pt: 0, pb: 1, mt: 0 }}>{children}</CardContent>
  </Card>
);

const FieldLabelEnCard = ({field,handleChange}) => {
  return <FieldCardInputGroup title={"English"}>
  <TextField
    fullWidth
    error={
      field.errors &&
      field.errors.title &&
      field.errors.title.length > 0
    }
    helperText={field.errors && field.errors.title}
    id={`title-${field.id}`}
    label={"Label (En)"}
    defaultValue={field.title}
    onBlur={handleChange}
  />
  <TextField
    fullWidth
    id={`placeholder-${field.id}`}
    label={"Placeholder (En)"}
    defaultValue={field.placeholder}
    onBlur={handleChange}
  />
</FieldCardInputGroup>
}

function CollapsableField({ fields = [], field, handleCategoryChange, deleteCallback }) {
  // console.log('CollapsableField()',{fieldData})
  const RESOURCE = "field";

  const isNew =
    typeof field.id === "string" && field.id.split("-")[0] === "new";
  const [expanded, setExpanded] = React.useState(isNew);
  const toggleExpand = (force) => {
    if (force && typeof force === "boolean") setExpanded(force);
    else setExpanded((prev) => !prev);
  };

  //FIELD DELETE HOOK
  const [deleteOne, { isLoading: isLoadingDelete }] = useDelete(
    RESOURCE,
    {},
    {
      onSuccess: (res) => {
        // console.log("deleteOne onSuccess()", res);
        deleteCallback && deleteCallback();
        toggleExpand();
      },
      onError: (res) => {
        console.log("deleteOne onError()", res);
      },
    }
  );

  const field_id = field && !isNew ? field.id : new Date().getTime();

  const updateField = ({ key, value }) => {
    const next = { ...field, [key]: value };
    handleCategoryChange &&
      handleCategoryChange({
        target: {
          id: RESOURCE,
          value: next,
        },
      });
  };

  const handleChange = (el) => {
    const key = el.target.id.split("-")[0];
    updateField({ key, value: el.target.value });
  };

  React.useEffect(() => {
    if (errorHelper.checkForErrors(field, {}) && !expanded) toggleExpand();
  }, [field]);

  const handleDelete = React.useCallback(() => {
    deleteOne(RESOURCE, {
      id: field.id,
      previousData: {...field},
    });
  }, [deleteOne]);

  const AccordianButtons = () => {
    const buttonProps = {
      variant: "text",
      sx: {
        opacity: 0.3,
        ":hover": {
          opacity: 1,
        },
      },
    };
    return (
      <ButtonGroup>
        {!fieldHelper.isSpecialType(field.type) && (
          <Button {...buttonProps} color={"error"}>
            <RemoveCircleOutline onClick={handleDelete} />
          </Button>
        )}

        {/* <Button {...buttonProps}>
          <ArrowCircleUp />
        </Button>
        <Button {...buttonProps}>
          <ArrowCircleDown />
        </Button> */}
      </ButtonGroup>
    );
  };

  const TypeSelect = React.useCallback(() => {
    const id = `type-${field_id}`;
    return (
      <FormControl fullWidth>
        <InputLabel id={`type-field-${field.id}`}>Type</InputLabel>
        <Select
          labelId={`type-field-${field_id}`}
          id={id}
          value={field.type}
          label="Type"
          onChange={(el) => handleChange({ target: { ...el.target, id } })}
        >
          
          <MenuItem value={"text"}>Text</MenuItem>
          <MenuItem value={"longtext"}>Long text</MenuItem>
          <MenuItem value={"upload"}>Upload file</MenuItem>
          <MenuItem value={"title"}>Submission title</MenuItem>
          <MenuItem value={"publication"}>Publication</MenuItem>
          
          {/* <MenuItem value={"mandate"}>Editorial mandate</MenuItem> */}
        </Select>
      </FormControl>
    );
  }, [field]);
  return (
    <Accordion
      expanded={expanded}
      defaultExpanded={isNew}
      elevation={0}
      fullWidth
      sx={{ width: "100%" }}
    >
      <AccordionSummary expandIcon={<ExpandMore onClick={toggleExpand} />}>
        <Grid container>
          <Grid item xs onClick={toggleExpand}>
            <Typography component={"span"} variant={"body1"}>
              {typeof field.priority === "number" ? (
                `${field.priority + 1}:`
              ) : (
                <FiberNew sx={{ verticalAlign: "bottom" }} />
              )}{" "}
              {field.title || "(Please add a label)"}
              {field.required && "*"}
            </Typography>
            {field.type && (
              <Typography
                component={"span"}
                variant={"body1"}
                sx={{ ml: 1, color: "#686868" }}
              >
                ({field.type})
              </Typography>
            )}
          </Grid>
          <Grid item>
            <AccordianButtons />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0, mb: 2 }}>
        <FieldLabelEnCard {...{field, handleChange}}/>
        <FieldCardInputGroup title={"French"}>
          <TextField
            fullWidth
            id={`title_fr-${field.id}`}
            label={"Label (Fr)"}
            defaultValue={field.title_fr}
            onBlur={handleChange}
          />
          <TextField
            fullWidth
            id={`placeholder_fr-${field.id}`}
            label={"Placeholder (Fr)"}
            defaultValue={field.placeholder_fr}
            onBlur={handleChange}
          />
        </FieldCardInputGroup>
        <FieldCardInputGroup title={"Properties"}>
          {!fieldHelper.isSpecialType(field.type) && <TypeSelect />}
          

          <TextField
            type={"number"}
            onWheel={(e) => e.target.blur()}
            fullWidth
            id={`priority-${field.id}`}
            label={"Order"}
            defaultValue={
              typeof field.priority === "number"
                ? field.priority
                : fields.length
            }
            onBlur={handleChange}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  id={`required-${field.id}`}
                  defaultChecked={field.required}
                  checked={field.required}
                  onChange={(el) => {
                    el.target.value = el.target.checked;
                    handleChange({
                      ...el,
                      target: {
                        ...el.target,
                        value: el.target.checked,
                        id: el.target.id,
                      },
                    });
                  }}
                />
              }
              label={`Is this field required?`}
            />
          </FormGroup>
        </FieldCardInputGroup>
      </AccordionDetails>
    </Accordion>
  );
}

export default CollapsableField;
