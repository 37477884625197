import _ from "lodash";
import React from "react";
import { ImportButton } from "react-admin-import-csv";

function ButtonImport({ type, year, organization }) {

  function getDataValues(values, extension = () => ({})) {
    console.log('getDataValues',{values,extension})
    return (
      values
        .filter(Boolean)
        .map((row) => {
          return {
            ...row,
            year,
            organization,
            title: row.title_en,
            ...extension(row),
          };
        })
    );
  }

  return (
    <ImportButton
      parseConfig={{
        beforeFirstChunk: (chunk) => {
          //Removes the top line of the imported sheet
          //These lines are used for mark-up
          const splitLines = chunk.split("\n");
          splitLines.shift();
          return splitLines.join("\n");
        },
      }}
      preCommitCallback={(action, values) => {
        // console.log("preCommitCallback() values", { action, values, type });
        switch (type) {
          case "judge":
            // const d = getDataValues(values, type);
            // console.log('getDataValues',{d})
            // throw new Error('Judge Import Not Configured')
            return getDataValues(values);
          case "category":

            const categoryData = {
              category: getDataValues(
                values.filter((row) => {
                  return row.type?.toLowerCase() === "category";
                }),
                (row) => ({
                  active: row.active && row.active.toLowerCase() === "true",
                  price: parseFloat(row.price),
                  discount: parseFloat(row.discount),
                  start: row.start.replace(/-/g, "/"),
                  end: row.end.replace(/-/g, "/"),
                  discountEnd: row.discountEndDate.replace(/-/g, "/"),
                })
              ),
              field: getDataValues(
                values.filter((row) => {
                  return row.type?.toLowerCase() === "field";
                }),
                (row) => ({
                  active: row.active && row.active.toLowerCase() === "true",
                  required:
                    row.required && row.required.toLowerCase() === "true",
                  type: row.field_type ? row.field_type : "text",
                })
              ),
            };

            // console.log("categoryData", categoryData);

            //Default Fields

            _.uniqBy(categoryData.field, "category_ref").map((ref) => {
              const { category_ref } = ref;

              if (
                categoryData.field.findIndex(
                  (field) =>
                    field.type === "title" &&
                    field.category_ref === category_ref
                ) < 0
              ) {
                // console.log("Adding title to categoryData.field for category_ref ",category_ref);
                categoryData.field.push({
                  title: "Submission Title",
                  title_fr: "Titre de la candidature",
                  placeholder: "Exactly as it should be credited",
                  placeholder_fr: "Exactement comme il faut le créditer",
                  required: true,
                  priority: 0,
                  type: "title",
                  category_ref,
                });
              }

              if (
                categoryData.field.findIndex(
                  (field) =>
                    field.type === "publication" &&
                    field.category_ref === category_ref
                ) < 0
              ) {
                // console.log("Adding publication to categoryData.field for category_ref ",category_ref);

                categoryData.field.push({
                  title: "Publication Name",
                  title_fr: "Nom de la publication",
                  placeholder: "Exactly as it should be credited",
                  placeholder_fr: "Exactement comme il faut le créditer",
                  required: true,
                  priority: 1,
                  type: "publication",
                  category_ref,
                });
              }

              if (
                categoryData.field.findIndex(
                  (field) =>
                    field.type === "mandate" &&
                    field.category_ref === category_ref
                ) < 0
              ) {
                // console.log("Adding mandate to categoryData.field for category_ref ",category_ref);

                categoryData.field.push({
                  title: "Editorial mandate",
                  title_fr: "Mission éditoriale",
                  placeholder: "Publication's editorial mandate statement",
                  placeholder_fr:
                    "Énoncé de la mission éditoriale de la publication",
                  required: true,
                  priority: 2,
                  type: "mandate",
                  category_ref,
                });
              }
            });

            return { ...categoryData };

          default:
            // console.log("DEFAULT TYPE");
            return values;
        }
      }}
      logging
    />
  );
}

export default ButtonImport;
