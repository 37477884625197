import { fetchUtils } from "react-admin";
import { constants } from "../config";

const httpClient = fetchUtils.fetchJson;
const baseUrl = [constants.api.url, constants.api.endpoint].join("");

const uploadService = {
  getAdminSignedUploadUrl: ({ id, fileName, type }, callback) => {
    httpClient(`${baseUrl}/upload/url`, {
      method: "POST",
      body: JSON.stringify({ data: { id, fileName, type } }),
    }).then((res) => {
      // console.log("getSignUploadUrl()", res);
      if (res.status === 200) callback && callback(res.json);
      return;
    });
  },
  uploadToSignedUrl: ({ signedUrl, file }, callback) => {
    httpClient(signedUrl, {
      body: file,
      method: "PUT",
      headers: new Headers({
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
        Accept: "application/json",
      }),
    }).then((res) => {
      // console.log("UPLOAD REQ()", res);
      return callback && callback(res);
    });
    //   .catch((e) => {
    //     console.log("Error Uploading(*)", e.message);
    //   });
  },
};

export default uploadService;
