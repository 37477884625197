import _ from "lodash";
import errorHelper from "./error";

const validate = ({ data, validationProps, callback, errorCallback }) => {
  const validatedForm = errorHelper.validateForm(data, validationProps);
  callback && callback(validatedForm);

  const check = errorHelper.checkForErrors(validatedForm, validationProps);

  errorCallback && errorCallback(check);
  return !check;
};

function updateState({ key, value, checked, options = {} }, callback) {
  // console.log('Form Helper updateState',{key, value, checked, options,callback})
  if (key === "field") {
    callback((prev) => {
      value.errors = {};

      let fields = [...prev.fields];
      const fieldIndex = fields.findIndex((el) => el.id === value.id);
      return {
        ...prev,
        fields: [...fields.splice(0, fieldIndex), value, ...fields.splice(1)],
      };
    });
  } else if (key === "newEntries") {
    // console.log("NEW ENTRIES FORM CHANGE");
    const { organization, category } = options;
    if (organization) {
      if (Array.isArray(value))
        value = value.map((entry) => ({ ...entry, organization }));
      else value.organization = organization;
    }

    if (category) {
      if (Array.isArray(value))
        value = value.map((entry) => ({ ...entry, category }));
      else value.category = category;
    }
    callback((prev) => {
      // console.log("updateState() newEntries callback()", { prev });
      const errors = { ...prev.errors };
      errors[key] = "";

      //Only take entries not in newEntry
      let newEntries = Array.isArray(value)
        ? _.uniqBy([...prev.newEntries, ...value], "id")
        : _.uniqBy([...prev.newEntries, value], "id");

        //Only take entries that are not in judge.entries
      return {
        ...prev,  
        errors,
        newEntries: _.remove(newEntries, (el,i,o)=>{
          const entryIds= prev.entries.map((el)=>el.entry_id)
          return entryIds.indexOf(el.id) < 0
        }),
      };
    });
  } else if (key === "removeNewEntry") {
    // console.log('REMOVE ENTRY...')
    callback((prev) => {
      const next = { ...prev };
      // console.log('NEXT',next)
      const newEntries = _.remove(
        next.newEntries,
        (entry) => entry.id !== value.id
      );
      // console.log('NEWENTRY',newEntries)
      return {
        ...next,
        newEntries,
      };
    });
  } else if (key === "removeAllNewEntries") {
    callback((prev) => ({
      ...prev,
      newEntries: [],
    }));
  } else if (key === "removeAddedEntry") {
    // console.log('REMOVE ADDED ENTRY...')
    callback((prev) => {
      const next = { ...prev };
      // console.log('NEXT',next)
      const entries = _.remove(
        next.entries,
        (entry) => entry.id !== value.id
      );

      const removeEntries = next.removeEntries ? [...next.removeEntries, value.id] : [value.id]
      // console.log('ENTRIES',entries)
      return {
        ...next,
        entries,
        removeEntries
      };
    });
  } else {
    callback((prev) => {
      const next = { ...prev };
      if (next.errors && next.errors[key]) next.errors[key] = "";
      return {
        ...next,
        [key]: key === 'active' ? checked : value,
      };
    });
  }
}

const formHelper = {
  validate,
  updateState,
};

export default formHelper;
