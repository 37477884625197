const createField = ({
  id = `new-${new Date().getTime()}`,
  title = "",
  title_fr = "",
  placeholder = "",
  placeholder_fr = "",
  type = "text",
  required = false,
  priority = 0,
  category_id = undefined,
} = {}) => ({
  id,
  title,
  title_fr,
  placeholder,
  placeholder_fr,
  type,
  required,
  priority,
  category_id,
});

const defaultFields = () => [
  createField({
    id: `new-title-${new Date().getTime()}`,
    type: "title",
    required: true,
    title: "Submission title",
    priority: 0,
  }),
  createField({
    id: `new-publication-${new Date().getTime()}`,
    type: "publication",
    required: true,
    title: "Publication name",
    priority: 1,
  }),
  createField({
    id: `new-mandate-${new Date().getTime()}`,
    type: "mandate",
    required: true,
    title: "Editorial mandate",
    priority: 2,
  }),
];


function isSpecialType(type){
   return "mandate".split(",").indexOf(type) >= 0  //title,publication,
}

const fieldHelper = {
  createField,
  defaultFields,
  isSpecialType,
};

export default fieldHelper;
