import { fetchUtils } from "react-admin";
import { constants } from "../config";


const httpClient = fetchUtils.fetchJson;
const baseUrl = [constants.api.url, constants.api.endpoint].join("");


const emailService = {
  sendJudgeEmail: ({id}, callback = ()=>{})=>{
    const url = `${baseUrl}/judge/email/${id}`
    httpClient(url,{
      method: 'POST',
    }).then(callback)
  },
  emailAllNewJudges: ({year}, callback = ()=>{})=>{
    console.log('EMAIL SERVER EMAIL ALL JUDGES',{year})
    const url = `${baseUrl}/judge/emailAllNew`
    httpClient(url,{
      method: 'POST',
    }).then(callback)
  },
 
};

export default emailService;
