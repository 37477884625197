import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import CreateLayout from "../../Common/CreateLayout";
import RecordTextField from "../../Common/RecordTextInput";
import CategoryFields from "../Edit/CategoryFields";
import CategoryLabelCard from "./CategoryLabelCard";
import CategoryPriceCard from "./CategoryPriceCard";

import fieldHelper from "../../../helper/field";
import dateTimeHelper from "../../../helper/dateTime";

const Sw = ({ id, active, handleChange }) => {
  return;
};

function CategorySummaryTab({
  category = {},
  handleChange = () => {},
  setCategory = () => {},
  loading = false,
  error = false,
  buttons = null,
  firstInputRef = null,
}) {
  const ActiveSwitch = () => {
    // console.log("ACTIVE SWITCH CAT", category);

    return (
      <FormControlLabel
        label={"Activate Category"}
        control={
          <Switch
            id={["active", category.id].join("-")}
            defaultChecked={category.active}
            checked={category.active}
            onChange={handleChange}
          />
        }
      />
    );
    // return React.useCallback(
    //   <FormControlLabel
    //     label={"Activate Category"}
    //     control={
    //       <Switch
    //         id={["active", category.id].join("-")}
    //         defaultChecked={category.active}
    //         checked={category.active}
    //         onChange={handleChange}
    //       />
    //     }
    //   />,
    //   [category, handleChange]
    // );
  };
  const FieldActions = () =>
    React.useCallback(
      <Grid container>
        <Grid item>
          <Button
            onClick={() => {
              setCategory((prev) => ({
                ...prev,
                fields: [
                  ...prev.fields,
                  fieldHelper.createField({
                    priority: prev.fields.length,
                    category_id: category.id,
                  }),
                ],
              }));
            }}
          >
            Add Field
          </Button>
        </Grid>
      </Grid>,
      [setCategory]
    );

  return (
    <CreateLayout
      errorLabel={`The category below could not be saved. Review the errors in the form and try again.`}
      {...{
        error,
        buttons,
        loading,
      }}
    >
      <Grid container spacing={4}>
        <Grid item sm={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <CategoryLabelCard {...{ category, handleChange, error }} />
            </Grid>
            <Grid item xs={12}>
              <CategoryPriceCard {...{ category, handleChange, error }} />
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  action={
                    <ActiveSwitch {...{ category, handleChange, error }} />
                  }
                  title={"On/Off Time"}
                />
                <CardContent>
                  <RecordTextField
                    type={"datetime-local"}
                    label={"Start accepting submissions"}
                    value={dateTimeHelper.convertDateToString(
                      new Date(category.start)
                    )}
                    id={category.id}
                    source={"start"}
                    handleChange={handleChange}
                  />
                  <RecordTextField
                    type={"datetime-local"}
                    label={"Stop accepting submissions"}
                    value={dateTimeHelper.convertDateToString(
                      new Date(category.end)
                    )}
                    id={category.id}
                    source={"end"}
                    handleChange={handleChange}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={6}>
          <Card>
            <CardHeader title={"Fields"} action={<FieldActions />} />
            <CardContent>
              <CategoryFields {...{ category, handleChange }} />
            </CardContent>
            <CardActions>
              <FieldActions />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </CreateLayout>
  );
}

export default CategorySummaryTab;
