import React from "react";
import {
  Create,
  TextInput,
  EditButton,
  SimpleForm,
  TabbedForm,
  FormTab,
  DateInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  TextField,
  required,
  Button,
  FormDataConsumer,
} from "react-admin";
// import { Card, CardHeader, Typography, Grid } from "@material-ui/core";
// import { parse } from "query-string";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";


const CreateFieldForm = () => {
  const [year] = useYear();
  const [organization] = useOrganization();
  // const form = useFormState();
  // console.log('FORM()',form)

  const [fieldYear, setFieldYear] = React.useState(year);
  const [fieldOrganization, setFieldOrganization] =
    React.useState(organization);

 
  const location = useLocation();
  const query = queryString.parse(location.search);

  console.log("query()", query);

  const category_id = query.category_id ? parseInt(query.category_id) : "";
  const redirect = category_id ? `/edit/category/${category_id}` : false;

  const CategorySelectInput = React.useCallback(() => {
    return (
      <ReferenceInput
        reference={"category"}
        source={"category_id"}
        label={"Category"}
        perPage={500}
        sort={{
          field: "title",
          order: "ASC",
        }}
        filter={{ year: fieldYear, organization: fieldOrganization }}
      >
        <SelectInput
          fullWidth
          source={"category_id"}
          optionText={"title"}
          validate={required()}
          defaultValue={category_id}
        />
      </ReferenceInput>
    );
  }, [fieldYear, fieldOrganization]);

  return (
    <SimpleForm>
      {/* <CardHeader
      title="Test"
       action= {category_id && <Button href={''}>Go back to Category</Button>}
      /> */}
        <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader title={"Field Labels"} />
            <CardContent>
              <Typography variant={"body2"}>English</Typography>
              <TextInput
                fullWidth
                validate={required()}
                source={"title"}
                label={"Field Label (En)"}
              />
              <TextInput
                fullWidth
                source={"placeholder"}
                label={"Placeholder (En)"}
              />
              <Divider />
              <Typography variant={"body2"}>French</Typography>
              <TextInput
                fullWidth
                validate={required()}
                source={"title_fr"}
                label={"Field Label (Fr)"}
              />
              <TextInput
                fullWidth
                source={"placeholder_fr"}
                label={"Placeholder (Fr)"}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card>
            <CardHeader title={"Properties"} action={ <BooleanInput
                fullWidth
                source={"required"}
                label={"Is this field required?"}
                defaultValue={false}
              />}/>
            <CardContent>
              <SelectInput
                fullWidth
                source={"year"}
                defaultValue={"2022"}
                validate={required()}
                onChange={(el) => {
                  setFieldYear(el.target.value);
                }}
                choices={[
                  { id: "2022", name: "2022" },
                  { id: "2021", name: "2021" },
                ]}
              />
              <SelectInput
                fullWidth
                source={"organization"}
                defaultValue={organization}
                validate={required()}
                onChange={(el) => {
                  setFieldOrganization(el.target.value);
                }}
                choices={[
                  { id: "NMA", name: "NMA" },
                  { id: "DPA", name: "DPA" },
                  { id: "B2B", name: "B2B" },
                ]}
              />
              <CategorySelectInput />
              <SelectInput
                fullWidth
                source={"type"}
                defaultValue={"text"}
                validate={required()}
                choices={[
                  { id: "text", name: "Text" },
                  { id: "longtext", name: "Long text" },
                  { id: "upload", name: "Upload file" },
                  { id: "title", name: "Submission title" },
                  { id: "publication", name: "Publication" },
                  { id: "mandate", name: "Editorial mandate" },
                ]}
              />
             
             <SelectInput
                fullWidth
                source={"priority"}
                label={'Sort Priority'}
                defaultValue={"end"}
                validate={required()}
                choices={[
                  { id: "start", name: "Place at start" },
                  { id: "end", name: "Place at end" },
                ]}
              />
            
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

function CreateField() {
  return (
    <Create>
      <CreateFieldForm />
    </Create>
  );
}

export default CreateField;
