import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import {
  Edit,
  TextInput,
  FormWithRedirect,
  BooleanInput,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  ReferenceOneField,
  NumberField,
  ReferenceField,
  DateField,
  TextField,
} from "react-admin";
import useOrganization from "../../hooks/useOrganization";
import useYear from "../../hooks/useYear";
// import { Card, CardContent, Grid, Typography } from "@material-ui/core";

const FieldEditForm = () => {
  // console.log("FieldEditForm()", { props });
  const record = useRecordContext();
  const [year] = useYear();
  const [organization] = useOrganization();
  // const { category_id, type } = props.record;
  return (
    <SimpleForm>
      <Grid container style={{ width: "100%" }} spacing={3}>
        <Grid item xs={12} sm={8}>
          <Card>
            <CardHeader
             
              title="Field Labels"
            />
            <CardContent>
              <Grid container style={{ width: "100%" }} spacing={2}>
                <Grid item xs={12}>
                <Typography variant="body2">English:</Typography>
                  <TextInput
                    fullWidth
                    source={"title"}
                    label={"Field Title (En)"}
                  />
                  <TextInput
                    fullWidth
                    source={"placeholder"}
                    label={"Placeholder (En)"}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider/>
                  <br/>
                  <Typography variant="body2">French:</Typography>
                  <TextInput
                    fullWidth
                    source={"title_fr"}
                    label={"Field Title (Fr)"}
                  />
                  <TextInput
                    fullWidth
                    source={"placeholder_fr"}
                    label={"Placeholder (Fr)"}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
         
        </Grid>
        <Grid item xs={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Properties" />
                <CardContent>
                  <SelectInput
                    fullWidth
                    source={"type"}
                    choices={[
                      { id: "text", name: "Text" },
                      { id: "longtext", name: "Long text" },
                      { id: "upload", name: "Upload file" },
                      { id: "title", name: "Submission title" },
                      { id: "publication", name: "Publication" },
                      { id: "mandate", name: "Editorial mandate" },
                    ]}
                  />
                  <BooleanInput
                  fullWidth
                  source={"required"}
                  label={"Is this field required?"}
                  initialValue={false}
                />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Category" action={
                  <ReferenceField reference="category" source="category_id">Edit</ReferenceField>
                } />
                <CardContent>
                  
                  <ReferenceField
                    label="Category Title"
                    reference="category"
                    source="category_id"
                    link={false}
                    filter={{ organization, year }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField variant="body1" disabled source="title" /><br/>
                        <TextField disabled source="title_fr" />
                      </Grid>
                                            
                    </Grid>
                  </ReferenceField>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

function EditField() {
  return (
    <Edit>
      <FieldEditForm />
    </Edit>
  );
}

export default EditField;
