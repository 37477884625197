import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  TopToolbar,
  CreateButton,
  ExportButton,
  BooleanField,
  Button as RAButton,
} from "react-admin";
import useYear from "../../hooks/useYear";
import useOrganization from "../../hooks/useOrganization";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import SelectYear from "../Common/SelectYear";
import SelectOrganization from "../Common/SelectOrganization";
import { Email, ExpandMore } from "@mui/icons-material";
import ButtonImport from "../Common/ButtonImport";
import emailService from "../../service/email";

const JudgeList = () => {
  const JUDGE_TEMPLATE = [
    "Full Name,Email,Temp. Password,Language,Entry IDs,Category IDs,Omit Entry IDs".split(
      ","
    ),
  ];
  const inputFileRef = React.createRef();
  const [year, setYear] = useYear();
  const [organization, setOrganization] = useOrganization();
  const filter = React.useCallback(() => {
    return { year, organization };
  }, [year, organization]);

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      let fileReader = new FileReader();

      const handleFileRead = (e) => {
        const contents = fileReader.result;      
        const ROW_DELIMITER = "\r\n";
        const COL_DELIMITER = ",";
        const csv = { headers: [], body: [] };
        csv.headers = contents
          .toString()
          .substring(0, contents.indexOf(ROW_DELIMITER))
          .split(COL_DELIMITER);
        csv.body = contents
          .toString()
          .substring(contents.indexOf(ROW_DELIMITER) + 2)
          .split(ROW_DELIMITER);

      };

      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file);

      let data = new FormData();
      data.append("file", file);

    }
  };

  const handleImportClick = () => inputFileRef.current.click();

  const ListActions = React.useCallback(() =>{
    return <TopToolbar>
      <CreateButton/>
      <ExportButton maxResults={9999}/>
      <ButtonImport type={'judge'} {...{organization, year}}/>
      <RAButton onClick={()=>emailService.emailAllNewJudges({year})} startIcon={<Email/>} label={'Send Welcome Email'} />
    </TopToolbar>
  },[year])

  return (
    <List
    perPage={999999}
    pagination={false}
    actions={<ListActions/>}
      empty={
        <Box sx={{ mt: 8, width: "100%" }}>
          <Card>
            <CardHeader title="No judges found" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant={"body1"}>
                    There are no judges for this year & program.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item>
                    <CreateButton/>
                    </Grid>
                    <Grid item>
                    <ButtonImport type={'judge'} {...{organization, year}}/>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      <CSVLink
                        data={JUDGE_TEMPLATE}
                        filename={"judge-template-nmaf-admin-v2023-1.csv"}
                      >
                        Download template
                      </CSVLink>
                    </Grid>
                    <Grid item xs></Grid>
                  </Grid>
                </Grid> */}
              </Grid>

              <Box></Box>
              <Accordion elevation={0} sx={{ mt: 2 }}>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{ pl: 0, pr: 0, borderTop: "none !important" }}
                >
                  Learn more about importing Judges
                </AccordionSummary>
                <AccordionDetails sx={{ pl: 0, pr: 0 }}>
                  Lorem ipsum...
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Box>
      }
      aside={
        <Box
          sx={{
            order: -1,
            mr: 1,
            mt: 8,
            width: 250,
          }}
        >
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs>
                  <SelectYear
                    value={year}
                    callback={setYear}
                    // fullWidth
                  />
                </Grid>
                <Grid item xs>
                  <SelectOrganization
                    value={organization}
                    callback={setOrganization}
                    // fullWidth
                  />
                </Grid>
              </Grid>

              {/* <FilterLiveSearch /> */}
            </CardContent>
          </Card>
        </Box>
      }
      filter={filter()}
    >
      <Datagrid rowClick="edit">

      <TextField source="fullName" label={"Name"} />
        <TextField source="email" label={"Email"} />
        <TextField source="tempPass" label={"Temp. Password"} />
        <BooleanField source="sentWelcome" label={"Emailed?"}/>
        <TextField source="currentYearCategories" label={"Categories"}/>
        <NumberField
          source="no_entries"
          textAlign={"center"}
          label={"Assigned"}
        />
        <NumberField
          source="no_completed"
          textAlign={"center"}
          label={"Scored"}
        />
      </Datagrid>
    </List>
  );
};

export default JudgeList;
