import { Typography } from "@mui/material";
import React from "react"



const Mono = ({ variant, children }) => (
    <Typography variant={'string'} sx={{fontFamily:'monospace', background:'#f0f0f0', padding: '1px 4px 2px',borderRadius: '2px'}}>
     {children}
    </Typography>
  );


  export default Mono