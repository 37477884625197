import { OpenInNew } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";

//judge.newEntries
function JudgeNewEntries({ entries, handleChange }) {
  return entries.map((entry) => (
    <Card
      key={`addedEntry-${entry.id}`}
      sx={(theme) => ({
        background: `${theme.palette.error}`,
      })}
    >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2">({entry.id})</Typography>
          </Grid>

          <Grid item xs>
            <Typography variant="body2">
              {entry.title || "(No title)"}
            </Typography>
            <Typography variant="body2">
              {entry.organization || "(NO ORG)"} {entry.category || "(NO CAT)"}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              target={"_blank"}
              href={`#/entry/${entry.entry_id}`}
              size={"small"}
              color={"inherit"}
              sx={{ height: "10px", mt: -0.5, mr: 1 }}
              endIcon={<OpenInNew sx={{ height: "15px" }} />}
            >
              Edit Entry
            </Button>
            <Button
              size={"small"}
              color={"error"}
              sx={{ height: "10px", mt: -0.5, mr: 1 }}
              onClick={() =>
                handleChange({
                  target: { id: "removeNewEntry", value: entry },
                })
              }
            >
              Remove Entry
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ));
}

export default JudgeNewEntries;
