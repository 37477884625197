import React from "react";
import {
  Edit,
  TextInput,
  DeleteButton,
  BooleanInput,
  TextField,
  SimpleForm,
  SelectInput,
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  DateField,
  required,
  TopToolbar,
} from "react-admin";

import {
  Card,
  CardHeader,
  Grid,
  Typography,
  CardContent,
  Link,
} from "@mui/material";
import UploadFields from "./UploadFields";

const EntryActions = () => (
  <TopToolbar>
    <DeleteButton />
  </TopToolbar>
);

const EntryEditForm = () => {
  const record = useRecordContext();

  let [paypalId, orderId] =
    record.invoice && record.invoice.payment_id
      ? record.invoice.payment_id.split("-")
      : [];

  return (
    <SimpleForm>
      <Typography variant={"h3"}>{record.title}</Typography>
      <Grid container spacing={4}>
        <Grid item xs>
          <Card elevation={1}>
            <CardHeader
              title="Submission Info"
              action={
                <BooleanInput source={"submitted"} label={"Submitted?"} />
              }
            />
            <CardContent>
              <Card elevation={0}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <strong>ID:</strong>{" "}
                          <TextField variant={"body"} source={"uuid"} />
                        </Grid>
                        <Grid item xs={12}>
                          <strong>Cateogry:</strong>{" "}
                          <TextField
                            variant={"body"}
                            source={"category.title"}
                            emptyText={"Could not determine Category"}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <strong>Created At:</strong>{" "}
                          <DateField
                            variant={"body"}
                            source={"createdAt"}
                            showTime
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <strong>Last Updated:</strong>{" "}
                          <DateField
                            variant={"body"}
                            source={"updatedAt"}
                            showTime
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <ArrayInput source="values" label="">
                <SimpleFormIterator
                  disableAdd
                  disableClear
                  disableRemove
                  disableReordering
                  fullWidth
                  sx={{ ".RaSimpleFormIterator-line": { border: "none" } }}
                >
                  <TextField
                    source="title"
                    sx={{ m: "0 0 -30px 12px", color: "#626262" }}
                  />
                  <TextInput source="value" label=" " fullWidth />
                </SimpleFormIterator>
              </ArrayInput>

              <Card elevation={0} sx={{ background: "#ffb74d22", mb:4 }}>
                <CardContent>
                  <Typography variant={"body1"}>
                    <strong>Warning</strong>
                  </Typography>
                  <Typography variant={"body2"}>
                  If you've updated any of the text fields above, please make sure to save this entry before uploading any files. Otherwise, you may lose your changes.

                  </Typography>
                </CardContent>
              </Card>
             
              <ArrayInput source="uploads" label="">
                <UploadFields/>
              </ArrayInput>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card elevation={1}>
                <CardHeader title="Judging" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectInput
                        fullWidth
                        label={"Win Status"}
                        source={"winStatus"}
                        validate={required()}
                        choices={[
                          {
                            id: "none",
                            name: "None",
                          },
                          {
                            id: "honorable",
                            name: "Honorable Mention",
                          },
                          { id: "silver", name: "Silver" },
                          {
                            id: "gold",
                            name: "Gold",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={1}>
                <CardHeader title="Payment" />
                <CardContent>
                  <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                      <Typography variant={"h4"}>Payment</Typography>
                    </Grid> */}
                    {record.invoice && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {/* <FileField
                              source={"invoice.pdf_link"}
                              title={"Download Invoice PDF"}
                              download
                            /> */}
                            <Link href={record.invoice.pdf_link}>
                              Download Invoice PDF
                            </Link>
                          </Grid>
                          <Grid item xs={12}>
                            <Link href={record.invoice.summary_link}>
                              Download Submission Summary
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <SelectInput
                        fullWidth
                        label={"Rebate"}
                        source={"rebate"}
                        validate={required()}
                        choices={[
                          {
                            id: "none",
                            name: "None",
                          },
                          {
                            id: "freelancer-unverified",
                            name: "Freelancer (Unverified)",
                          },
                          { id: "freelancer", name: "Freelancer" },
                          {
                            id: "smallMagazine-unverified",
                            name: "Small Magazine (Unverified)",
                          },
                          { id: "smallMagazine", name: "Small Magazine" },
                        ]}
                      />
                      <SelectInput
                        fullWidth
                        label={"Payment Status"}
                        source={"payment"}
                        validate={required()}
                        choices={[
                          { id: "unpaid", name: "Unpaid" },
                          { id: "paid-discount", name: "Paid (Discount)" },
                          { id: "paid", name: "Paid" },
                          { id: "waived", name: "Waived" },
                          { id: "returned", name: "Returned" },
                        ]}
                      />
                      <BooleanInput
                        source={"cheque"}
                        label={"Pay by Cheque?"}
                      />
                    </Grid>
                    {record.invoice && record.invoice.payment_id && (
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Typography variant="body2">
                              <strong>Payment ID:</strong> {paypalId}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              <strong>Order ID:</strong> {orderId}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={1}>
                <CardHeader title="Submitter" />
                <CardContent>
                  <TextInput disabled fullWidth source={"user.username"} />
                  <TextInput disabled fullWidth source={"user.email"} />
                  <TextInput disabled fullWidth source={"user.fullName"} />
                  <TextInput disabled fullWidth source={"user.uuid"} />
                  <BooleanInput disabled fullWidth source={"user.active"} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  );
};

function EditEntry(props) {
  // console.log("EditEntry()", props);

  return (
    <Edit actions={<EntryActions />} redirect={false}>
      {/* aside={<EditAside />} */}
      {/* actions={<EntryActions {...props} />} */}
      <EntryEditForm />
    </Edit>
  );
}

export default EditEntry;
