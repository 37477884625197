import React from "react";
import {


  EditBase,
} from "react-admin";
import JudgeEditInfo from "./JudgeEditInfo";


function JudgeEdit() {
  return (
    <EditBase>
      {/* actions={<SubmitterActions {...props} />} */}
      <JudgeEditInfo/>
    </EditBase>
  );
}

export default JudgeEdit;
