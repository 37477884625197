import { Delete, ExpandMore, OpenInNew, Save } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useEditContext, useRecordContext } from "react-admin";
import formHelper from "../../../helper/form";
import judgeHelper from "../../../helper/judge";
import CreateLayout from "../../Common/CreateLayout";
import JudgeInfoCard from "../Common/JudgeInfoCard";

function AccordionJudgeScore({ entry, handleChange }) {
  const [expanded, setExpanded] = React.useState(false);
  const toggleExpanded = () => setExpanded((prev) => !prev);

  const EntryScore = ({ score }) => {
    const scoreIndex = judgeHelper.getScoreCodeIndex(score);

    return (
      <Typography
        variant="body1"
        color={"error,warning,primary".split(",")[scoreIndex]}
      >
        {["(Not Scored)", "Conflict", score][scoreIndex]}
      </Typography>
    );
  };

  return (
    <Accordion
      expanded={expanded}
      sx={{
        "&:hover": {
          cursor: "e-resize !important",
        },
      }}
    >
      {/* expandIcon={<ExpandMore />} */}
      <AccordionSummary>
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant="body2">({entry.entry_id})</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="body2">
              {entry.title || "(No title)"}
            </Typography>
            {/* <Typography variant="body2">
              {entry.Organization.shortName || "(NO ORG)"} {entry.Category.title || "(NO CAT)"}
            </Typography> */}
          </Grid>
          <Grid item>
            <EntryScore score={entry.score} />
          </Grid>
          <Grid item>
            <Button
              onClick={toggleExpanded}
              size={"small"}
              color={"inherit"}
              sx={{ height: "10px", mt: -0.5, mr: 1 }}
            >
              {expanded ? "Hide" : "Show"} Options
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs></Grid>
          <Grid item>
            <Button
              target={"_blank"}
              href={`#/entry/${entry.entry_id}`}
              size={"small"}
              color={"inherit"}
              sx={{ height: "10px", mt: -0.5, mr: 1 }}
              endIcon={<OpenInNew sx={{ height: "15px" }} />}
            >
              Edit Entry
            </Button>
            <Button
              size={"small"}
              color={"error"}
              sx={{ height: "10px", mt: -0.5, mr: 1 }}
              onClick={() =>
                handleChange({
                  target: { id: "removeAddedEntry", value: entry },
                })
              }
            >
              Remove Entry
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionJudgeScore;
