import React from "react";
import { Box } from "@mui/material";

import {

  CreateBase as RACreateBase,

} from "react-admin";

function CreateBase({children}) {
  
  return (
    <RACreateBase>
      <Box sx={{ width: "100%", mb: 4 }}>
        {children}
      </Box>
    </RACreateBase>
  );
}

export default CreateBase;
